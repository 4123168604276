.app-settings-color {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.app-settings-theme-editor-container {
    flex-grow: 1;
}

.app-settings-theme-editor-title {
  font-size: 11pt;
  font-weight: bold;
  margin-left: 2px;
}

.app-settings-theme-editor-label {
  align-items: center;
  display: flex;
  font-size: 10pt;
  height: 28px;
  padding-bottom: 8px;
}