.documents-container {
  background-color: transparent;
  border-radius: 11px;
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  max-height: 400px;
  overflow: scroll;
  padding: 2px;
  padding-bottom: 6px;
  padding-left: 6px;
  padding-top: 12px;
  position: relative;
}

.documents-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 8px;
}

.documents-label {
  font-size: 10pt;
  font-weight: normal;
  margin-left: 12px;
  user-select: none;
}

.documents-footer {
  display: row;
  flex-direction: row;
}

.documents-upload-button {
  background-color: transparent;
  border: 0px solid transparent;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 11pt;
  font-weight: bold;
  height: 30px;
  justify-content: left;
  margin-left: 14px;
  margin-top: 20px;
  margin-bottom: 2px;
  width: auto;
}

.documents-list {
  margin-top: 16px;
  padding-left: 12px;
  padding-right: 12px;
  width: 100%;
}

.documents-document {
  border-width: 0px;
  border-style: dotted;
  border-radius: 11px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  margin-right: 8px;
  padding-bottom: 8px;
  padding-top: 8px;
}

.documents-document-left {
  padding-right: 10px;
  width: auto;
}

.documents-document-center {
  flex-shrink: 1;
  width: 100%;
}

.documents-document-right {
  padding-left: 10px;
  width: auto;
}

.documents-document-name {
  font-size: 12pt;
  font-weight: bold;
  overflow: hidden;
}

.documents-document-properties {
  align-items: center;
  display: flex;
  flex-direction: row;
  padding-top: 8px;
}

.documents-document-property {
  font-size: 9pt;
}

.documents-document-property-dot {
  margin-left: 12px;
  margin-right: 12px;
}