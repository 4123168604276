.channel-add-label {
    font-size: 10pt;
    margin-top: 14px;
    margin-left: 6%;
    margin-right: 6%;
    width: 90%;
}

.channel-add-input {
    border-style: solid;
    border-radius: 5px;
    border-width: 1px;
    margin-bottom: 18px;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 20px;
    outline: none;
    padding: 12px;
    width: 90%;
}

.channel-add-privacy-label {
    align-items: center;
    display: flex;
    font-size: 10pt;
    font-weight: bold;
    padding-left: 5px;
}

.channel-add-privacy-section {
    display: flex;
    flex-direction: column;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 20px;
    width: 90%;
}

.channel-add-privacy-section-top {
    display: flex;
    justify-content: space-between;
}

.channel-add-privacy-section-bottom {
    display: flex;
    font-size: 10pt;
    justify-content: left;
    padding: 12px;
    padding-left: 4px;
    padding-right: 4px;
}

.channel-add-buttons {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    width: 100%;
}

.channel-add-button {
    background: transparent;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 10pt;
    font-weight: bold;
    padding: 10px 20px;
}