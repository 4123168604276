
.channel-privacy-label {
    align-items: center;
    display: flex;
    font-size: 10pt;
    font-weight: bold;
    padding-left: 5px;
}

.channel-privacy-section-top {
    display: flex;
    justify-content: space-between;
}

.channel-privacy-section-bottom {
    display: flex;
    font-size: 10pt;
    justify-content: left;
    padding: 12px;
    padding-left: 4px;
    padding-right: 4px;
}