.channels-group-title {
    font-size: 11pt;
    font-weight: bold;
    margin-left: 20px;
    user-select: none;
}

.channels-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    padding-top: 10px;
    transition: width 0.1s ease-in;
}