.app-logo-container {
    align-items: center;
    display: flex; 
    flex-direction: column;
    justify-content: center;
    height: 100%; 
    width: 100%;
}

.app-logo-title {
    font-size: 14pt;
    font-weight: bold;
    margin-top: 20px;
}