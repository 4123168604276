.documents-preview-container {
  background-color: transparent;
  border-radius: 11px;
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  padding: 2px;
  padding-bottom: 6px;
  padding-left: 4px;
  padding-top: 12px;
}

.documents-preview-label {
  font-size: 10pt;
  margin-left: 12px;
}

.documents-preview-title {
  flex-grow: 1;
  font-size: 12pt;
  font-weight: bold;
  margin-left: 11px;
  height: 100px;
  padding-top: 22px;
  padding-left: 8px;
}