.account-menu-summary-container {
    border-width: 2px;
    border-style: solid;
    border-color: transparent;
    border-radius: 9px;
    cursor: pointer;
    flex-grow: 1;
    margin-bottom: 10px;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px;
    overflow: hidden;
    position: relative;
    user-select: none;
    height: 90px;
}

.account-menu-summary-title {
    position: absolute;
    font-size: 12pt;
    font-weight: bold;
    margin-left: 10px;
    margin-top: 10px;
}

.account-menu-summary-description {
    position: absolute;
    font-size: 14px;
    font-weight:  normal;
    margin-left: 10px;
    margin-top: 40px;
    text-wrap: wrap;
    width: 100%;
}

.account-menu-summary-divider {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    margin-bottom: 9px;
}