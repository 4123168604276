.app-title-slash-container {
    align-items: center;
    cursor: pointer;
    display: flex;
    font-family: 'Open Sans', 'Roboto', 'Arial';
    font-size: 12pt;
    height: 100%;
    margin-left: 6px;
    text-wrap: nowrap;
    user-select: none;
}