.form-success-message-container {
    align-items: center;
    border-width: 1px;
    border-style: solid;
    border-radius: 9px;
    display: flex;
    flex-direction: row;
    font-size: 10pt;
    justify-content: center;
    margin-bottom: 12px;
    padding: 12px;
    width: 100%;
}