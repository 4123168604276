.lookup-container {
  background-color: transparent;
  border-radius: 11px;
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  padding: 2px;
  padding-left: 4px;
}

.lookup-input {
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 7px;
  font-size: 12pt;
  font-weight: bold;
  margin-top: 10px;
  margin-right: 20px;
  outline: none;
  padding: 6px;
  padding-left: 7px;
}

.lookup-value {
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 7px;
  font-size: 12pt;
  font-weight: bold;
  margin-left: 5px;
  margin-top: 10px;
  margin-right: 20px;
  outline: none;
  padding: 6px;
  padding-left: 7px;
}

.lookup-label {
  font-size: 10pt;
  margin-top: 12px;
  margin-left: 12px;
  user-select: none;
}

.lookup-header {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 8px;
}