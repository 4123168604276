.calendar-month-container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 100px);
}

.calendar-month-header {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background-color: transparent;
  border-bottom: 1px solid #242424;
  padding: 10px 0;
}

.calendar-month-day {
  flex-grow: 1;
  text-align: center;
}

.calendar-month-days {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
}

.calendar-month-cell {
  width: calc(100% / 7);
  text-align: center;
  cursor: pointer;
  border-bottom: 1px solid;
  border-right: 1px solid;
  padding: 2px;
}

.calendar-month-day-header {
  padding: 10px;
  font-size: 9pt;
}

.calendar-month-cell:last-child {
  border-right: none;
}

.calendar-month-day-events {
  width: 100%;
}

.calendar-month-event {
  border-radius: 3px;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  overflow: hidden;
  height: 18px;
  padding: 5px;
  padding-top: 2px;
  pointer-events: auto;
  user-select: none;
  width: 100%;
  z-index: 3;
}

.calendar-month-event-object {
  font-size: 8pt;
  font-weight: normal;
  margin-bottom: 4px;
  text-wrap: nowrap;
  overflow: hidden;
}
