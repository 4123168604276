.context-menu-button-container {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.context-menu-button-icon {
  align-items: center;
  align-content: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 3px;
  text-align: center;
  user-select: none;
}