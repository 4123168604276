.forum-post-form-button-container {
    align-items: center;
    border-radius: 11px;
    cursor: pointer;
    display: flex;
    font-size: 11pt;
    font-weight: bold;
    height: 36px;
    margin-right: 14px;
    margin-top: 13px;
    padding-left: 12px;
    padding-right: 12px;
    text-align: center;
    width: auto;
}