.title-bar-container {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 50px;
    position: relative;
    user-select: none;
    width: 100%;
}

.title-bar-app {
    align-items: center;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.title-bar-current-user {
    cursor: pointer;
    font-size: 10pt;
    font-weight: normal;
    margin: 8px;
    margin-left: 14px;
    padding-right: 8px;
    overflow: hidden;
    text-wrap: nowrap;
}

.title-bar-left,
.title-bar-right {
    display: flex;
    flex: 1;
    font-size: 12pt;
    font-weight: bold;
    height: 50px;
    margin-bottom: 3px;
    flex-direction: row;
    padding-top: 1px;
}

.title-bar-left {
    display: flex;
    flex-direction: row;
    margin-left: 10px;
}

.title-bar-right {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    padding-right: 2px;
}

.title-bar-logo {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    font-family: 'Open Sans', 'Roboto', 'Arial';
    font-size: 12pt;
    font-weight: bold;
    margin-left: 8px;
    user-select: none;
}

.title-bar-buttons {
    align-items: center;
    display: flex;
    flex-direction: row;
}

.active {
    font-weight: normal;
}

.title-bar-buttons {
    display: flex;
    flex-direction: row;
    margin-right: 10px;
    margin-top: 2px;
}

.title-bar-button {
    cursor: pointer;
    margin-left: 22px;
}

@media (max-width: 768px) {

    .title-bar-buttons {
        display: none;
    }

}