.chat-container {
  display: flex;
  flex-direction: row;
  width: calc(40% + 440px);
  height: calc(100vh - 50px);
}

.chat-container-mobile {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 2000px;
}

.chat-left {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 540px;
}

.chat-right {
  border-left: 1px solid;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 30%;
}

@media screen and (max-width: 768px) {

  /* Go wide since we're not showing users */
  .chat-left {
    height: calc(100vh - 50px);
    width: 100%;
  }

  .chat-right {
    display: none;
  }

}