.welcome-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.welcome-center {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin: auto;
    height: auto;
    width: 960px;
}

.welcome-left {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: auto;
    justify-content: right;
    padding-bottom: 30px;
    padding-right: 30px;
    text-align: right;
    user-select: none;
    width: 50%;
}

.welcome-divider {
    display: block;
    height: 500px;
    opacity: .5;
    width: 2px;
}

.welcome-right {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: auto;
    justify-content: left;
    padding-right: 30px;
    text-align: left;
    user-select: none;
    width: 50%;
}

.welcome-login-container {
    display: flex;
    justify-content: center;
    width: 100%;
}

.welcome-logo-container {
    display: inline-flex;
    flex-direction: row;
    justify-content: right;
}

.welcome-install-container {
    margin: 10px;
}

.welcome-form-wrapper {
    width: 340px;
}

/* Styles for small screens */
@media screen and (max-width: 768px) {

    .welcome-login-container {
        align-items: center; 
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 0px;
    }

    .welcome-center {
        margin-top: 40px;
        width: 100%;
    }

    /* Stack left/right vertically */
    
    .welcome-left {
        align-items: center;
        justify-content: center;
        padding-bottom: 8px;
        padding-left: 40px;
        padding-top: 30px;
        text-align: center;
        width: 300px;
    }

    .welcome-logo-container {
        align-items: center;
        display: inline-flex;
        flex-direction: column;
    }

    .welcome-divider {
        display: none;
        height: 1px;
        margin-top: 6px;
        margin-bottom: 16px;
        opacity: .5;
        width: 200px;
    }
    
    .welcome-right {
        align-items: center;
        justify-content: center;
        padding-bottom: 30px;
        padding-left: 40px;
        padding-top: 30px;
        text-align: center;
        width: 300px;
    }
    
    .welcome-form-wrapper {
        width: 300px;
    }
    
  }