.user-button-container {
    align-items: center;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    height: 24px;
    overflow: hidden;
    width: 24px;
}

.user-button-icon {
    height: 12px;
    width: 12px;
}

.account-billing-container {
    width: 430px !important;
}