.prompt-container {
    border-width: 1px;
    border-style: solid;
    border-radius: 13px;
    box-sizing: border-box;
    margin: auto;
    margin-top: 60px;
    position: relative;
    width: 100%;
}

.prompt {
    background-color: transparent;
    border: 0px;
    box-sizing: border-box;
    font-size: 11pt;
    height: 50px;
    margin: 5px;
    line-height: 1.4em;
    outline: none;
    overflow: hidden;
    padding: 16px;
    padding-bottom: 25px;
    resize: none;
    width: 85%;
}

.prompt-image {
    bottom: 16px;
    cursor: pointer;
    position: absolute;
    right: 18px;
    width: 28px;
}

/* Styles for small screens */
@media screen and (max-width: 768px) {

    .prompt-container {
        margin-top: 10px;
        width: 95%;
    }

}