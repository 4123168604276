.app-title-container {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 8px;
    padding-left: 8px;
    padding-right: 8px;
    width: 100%;
}

.app-title-image {
    height: 18px;
}

.app-title-name {
    align-items: center;
    display: flex;
    font-size: 16t;
    text-wrap: nowrap;
    user-select: none;
}