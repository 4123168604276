.barcode-preview-button {
  border-radius: 9px;
  border-style: solid;
  border-width: 1px;
  height: 50px;
  margin-left: 8px;
  margin-right: 4px;
  padding-left: 16px;
  padding-right: 16px;
  text-wrap: nowrap;
}

.barcode-preview-container {
  background-color: transparent;
  border-radius: 11px;
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  padding: 2px;
  padding-bottom: 6px;
  padding-left: 4px;
  padding-top: 12px;
}

.barcode-preview-field-container {
  display: flex;
  margin-top: 10px;
  flex-direction: row;
}

.barcode-preview-input {
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 7px;
  flex-grow: 1;
  font-size: 12pt;
  font-weight: bold;
  outline: none;
  padding: 12px;
}

.barcode-preview-label {
  font-size: 10pt;
  margin-left: 12px;
}