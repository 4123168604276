.app-logo-edit-container {
    display: flex;
    flex-direction: column;
    height: auto;
    z-index: 500;
}

.app-logo-edit-circle-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 100px;
    width: 100%;
}

.app-logo-edit-circle {
    align-items: center;
    border-width: 1px;
    border-style: solid;
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    height: 200px;
    justify-content: center;
    margin-bottom: 22px;
    margin-top: 12px;
    overflow: hidden;
    width: 200px;
}

.app-logo-edit-title {
    font-size: 11pt;
    font-weight: bold;
    margin-left: 2px;
    margin-bottom: 10px;
    text-align: center;
}