.event-form-container {
    height: calc(100vh - 50px);
    position: relative;
}

.event-form-sections {
    height: calc(100vh - 60px);
    margin-top: 10px;
    overflow-y: auto;
    padding-bottom: 400px;
    padding-left: 10px;
    padding-right: 10px;
    position: absolute;
    width: 100%;
    z-index: 1;
}

.event-form-buttons-mask {
    bottom: 0px;
    left: 0px;
    height: 70px;
    opacity: 0.7;
    position: absolute;
    right: 0px;
    z-index: 2;
}

.event-form-buttons {
    align-items: center;
    bottom: 0px;
    display: flex;
    flex-direction: row;
    gap: 20px;
    height: 70px;
    justify-content: right;
    left: 0px;
    padding: 14px;
    position: absolute;
    right: 0px;
    width: 100%;
    z-index: 2;
}