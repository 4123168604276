.account-menu-toolbar-container {
    align-content: top;
    background-color: transparent;
    box-sizing: border-box;
    display: flex;
    height: 30px;
    justify-content: space-between;
    padding-top: 4px;
}

.account-menu-toolbar-title {
    font-size: 16pt;
    font-weight: bold;
    margin-left: 20px;
}

.account-menu-toolbar-left {
    align-items: center;
    background-color: transparent;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    font-size: 16pt;
    font-weight: bold;
    justify-content: left;
    padding-top: 4px;
    padding-left: 18px;
    text-wrap: nowrap;
    user-select: none;
    width: 70%;
}

.account-menu-toolbar-right {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: right;
    height: 50px;
    width: 30%;
}