.app-settings-business-model-container {
    margin-bottom: 18px;
}

.app-settings-business-model-label {
    font-size: 10pt;
    margin-left: 2px;
}

.app-settings-business-models {
    height: 280px;
    margin-bottom: 22px;
    margin-top: 12px;
    overflow-y: auto;
}

.app-settings-business-model-input {
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    font-size: 12pt;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 8px;
    outline: none;
    padding: 12px;
    width: 150px;
}

.app-settings-business-models-wrapper {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    margin-bottom: 4px;
}

.app-settings-business-model-item {
    border-radius: 7px;
    cursor: pointer;
    margin-bottom: 12px;
    margin-right: 12px;
    padding: 12px;
}

.app-settings-business-model-item-title {
    font-size: 12pt;
    font-weight: bold;
    margin-bottom: 4px;
    margin-left: 4px;
}

.app-settings-business-model-item-description {
    font-size: 9pt;
    font-weight: normal;
    margin-bottom: 8px;
    margin-left: 4px;
    margin-right: 4px;
    margin-top: 8px;
}