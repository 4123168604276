.forum-post-thumb-container {
    border-radius: 11px;
    height: 70px;
    overflow: hidden;
    width: 70px;
}

.forum-post-thumb {
    height: 100%;
    object-fit: cover;
    object-position: center;
    width: 100%;
}