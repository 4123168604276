.chat-user-container {
    flex-grow: 1;
    margin-bottom: 10px;
    overflow: hidden;
    position: relative;
    user-select: none;
    height: 68px;
}

.chat-user-name {
    font-size: 14pt;
    font-weight: bold;
    height: auto;
    margin-left: 12px;
    margin-top: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}