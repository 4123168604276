.signup-link-container {
    align-items: center;
    border-width: 0px;
    border-radius: 7px;
    box-sizing: border-box;
    font-size: 10pt;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 14px;
    margin-top: 14px;
    outline: none;
    padding: 13px;
    width: 200px;
}