.login-username-container {
    padding-left: 30px;
    padding-right: 30px;
    width: 100%;
}

.login-username-input {
    border-width: 0px;
    border-radius: 7px;
    box-sizing: border-box;
    padding: 13px;
    justify-content: center;
    margin-bottom: 14px;
    outline: none;
    width: 100%;
}

.login-username-error {
    font-size: 9pt;
    margin-left: 8px;
    margin-bottom: 20px;
    width: 100%;
}

/* Styles for small screens */
@media screen and (max-width: 768px) {

    .login-username-container {
        padding-left: 0px;
        padding-right: 0px;
    }

    .login-username-error {
        margin-left: 0px;
    }

}