.month-picker-container {
  display: flex;
  flex-direction: column;
}

.month-picker-header {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background-color: transparent;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  padding: 10px 0;
}

.month-picker-day {
  font-size: 10pt;
  flex-grow: 1;
  text-align: center;
}

.month-picker-days {
  display: flex;
  flex-wrap: wrap;
}

.month-picker-cell {
  font-size: 9pt;
  width: calc(100% / 7);
  padding: 10px;
  text-align: center;
  cursor: pointer;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-right-width: 1px;
  border-right-style: solid;
}

.month-picker-cell:last-child {
  border-right: none;
}

.month-picker-arrow {
  height: 8px;
  margin-left: 12px;
  margin-top: 6px;
  width: 17px;
}
