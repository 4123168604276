.day-container {
  background-color: transparent;
  border-radius: 11px;
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  padding: 2px;
  padding-bottom: 6px;
  padding-right: 12px;
  padding-left: 4px;
  padding-top: 12px;
}

.day-input {
  background-color: transparent;
  border: 1px dotted transparent;
  border-radius: 5px;
  font-size: 12pt;
  font-weight: bold;
  margin-right: 16px;
  margin-top: 6px;
  outline: none;
  padding: 6px;
  padding-left: 7px;
}
