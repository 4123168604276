.calendar-toolbar-container {
    display: flex;
    flex-direction: column;
}
.calendar-toolbar-background {
    background-color: transparent;
    box-sizing: border-box;
    align-content: top;
    display: flex;
    height: 50px;
    justify-content: space-between;
}

.calendar-toolbar-nav {
    display: flex;
    flex-direction: row;
    margin-left: 10px;
    margin-right: 10px;
    padding-top: 2px;
    user-select: none;
    width: auto;
}

.calendar-toolbar-nav-button {
    cursor: pointer;
    width: 30px;
}

.calendar-toolbar-title {
    font-size: 12pt;
    font-weight: bold;
    margin-left: 20px;
    margin-bottom: 3px;
    user-select: none;
}

.calendar-toolbar-schedule {
    align-items: center;
    background-color: transparent;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    font-size: 16pt;
    font-weight: bold;
    height: 50px;
    justify-content: left;
    padding-left: 18px;
    text-wrap: nowrap;
    user-select: none;
    width: 70%;
}

.calendar-toolbar-buttons {
    background-color: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.calendar-toolbar-button {
    align-content: center;
    background-color: transparent;
    border-radius: 7px;
    margin-left: 11px;
    height: 30px;
    text-align: center;
    width: 30px;
    user-select: none;
}
.calendar-toolbar-button-image {
    width: 18px;
}
.calendar-toolbar-left {
    align-items: center;
    background-color: transparent;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: left;
    height: 50px;
}
.calendar-toolbar-right {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    height: 50px;
    justify-content: right;
    padding-right: 4px;
    width: 50px;
}
.calendar-toolbar-add-image {
    width: 14px;
}
.calendar-toolbar-delete-button {
    align-content: center;
    background-color: transparent;
    border-radius: 7px;
    margin-right: 12px;
    height: 30px;
    text-align: center;
    width: 30px;
    user-select: none;
}
.calendar-toolbar-delete-button-image {
    width: 12px;
}