.chat-post-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.chat-post-left {
    display: flex;
    justify-content: space-around;
    width: auto;
}

.chat-post-right {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.chat-post-header {
    display: flex;
    flex-direction: row;
}

.chat-post-user {
    align-items: center;
    cursor: pointer;
    display: flex;
    font-size: 11pt;
    font-weight: bold;
    margin-left: 12px;
    width: auto;
}

.chat-post-gallery-wrapper {
    align-items: center;
    border-radius: 17px;
    display: flex;
    height: 300px;
    margin-left: 45px;
    margin-right: 12px;
    margin-top: 18px;
    flex-direction: row;
    justify-content: center;
    overflow: hidden;
    position: relative;
    width: 440px;
}

.chat-post-dot {
    display: flex;
    justify-content: space-around;
    width: auto;
}

.chat-post-user {
    align-items: center;
    cursor: pointer;
    display: flex;
    font-size: 11pt;
    font-weight: bold;
    margin-left: 12px;
    width: auto;
}

.chat-post-posted {
    font-weight: normal;
}

.chat-post-timestamp {
    align-items: center;
    display: flex;
    flex-direction: row;
    font-size: 9pt;
    font-weight: normal;
    margin-left: 8px;
}

.chat-post-content {
    font-size: 11pt;
    margin-left: 12px;
    padding-bottom: 0px;
    padding-top: 4px;
    white-space: pre-wrap;
    /* Preserve spaces and line breaks */
    word-wrap: break-word;
    /* Prevent long words from overflowing */
}

.chat-post-comment-section {
    margin-left: 42px;
}

.chat-post-documents-wrapper {
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    padding-bottom: 6px;
    padding-right: 20px;
    padding-top: 12px;
}

.chat-post-document {
    align-items: center;
    border-radius: 9px;
    cursor: pointer;
    display: flex;
    flex-shrink: 1;
    gap: 12px;
    margin-right: auto;
    margin-top: 5px;
    padding: 12px;
    padding-left: 16px;
    padding-right: 20px;
    width: auto;
}

.chat-post-document-filename {
    font-size: 10pt;
    font-weight: bold;
}

.chat-post-object-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: left;
}

.chat-post-object {
    border-radius: 13px;
    margin: 20px;
    margin-left: 45px;
    padding: 8px;
    width: 440px;
}

.chat-post-events-wrapper {
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    padding-bottom: 6px;
    padding-right: 20px;
    padding-top: 12px;
}

.chat-post-event {
    align-items: center;
    border-radius: 9px;
    cursor: pointer;
    display: flex;
    flex-shrink: 1;
    gap: 12px;
    margin-right: auto;
    margin-top: 5px;
    padding: 12px;
    padding-left: 16px;
    padding-right: 20px;
    width: 70%;
}

@media (max-width: 600px) {

    .chat-post-gallery-wrapper {
        height: 200px;
        margin: 0px;
        margin-top: 20px;
        width: 100%;
    }
    .chat-post-object {
        margin: 0px;
        margin-top: 20px;
        margin-left: 0px;
        width: 100%;
    }

}