.run-home-container {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 50px);
    width: calc(40% + 440px);
}

.run-home-container-mobile {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 50px);
    width: 100%;
}

.run-home-feed {
    border-right-width: 1px;
    border-right-style: solid;
    flex-grow: 1;
    overflow-y: scroll;
    width: 600px;
}

.run-home-feed-mobile {
    height: calc(100vh - 50px);
    overflow-y: scroll;
    width: 100%;
}

.run-home-headline {
    flex-shrink: 1;
    font-size: 11pt;
    font-weight: normal;
    height: auto;
    line-height: 1.5em;
    margin-left: 20px;
    margin-right: 20px;
    width: 100%;
}

.run-home-headline-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.run-home-headline-remove-container {
    cursor: pointer;
    width: 40px;
}

.run-home-divider {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    margin: 20px;
}

.run-home-token {
    cursor: pointer;
    font-size: 11pt;
    font-weight: bold;
    user-select: none;
}

.run-home-post-text {
    font-size: 11pt;
}

.run-home-headline-timestamp {
    font-size: 9pt;
    font-weight: normal;
    margin-left: 8px;
}

/* Styles for small screens */
@media screen and (max-width: 768px) {
    
}