.vertical-handle-container {
    cursor: grab;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 10px;
    margin-bottom: 20px;
    margin-top: 2px;
    padding-top: 2px;
    padding-bottom: 3px;
    width: 100px;
}

.vertical-handle-line {
    height: 1px;
    width: 100%;
}