.app-settings-title-container {
    margin-bottom: 18px;
}

.app-settings-title-count {
    font-size: 8pt;
    padding-right: 2px;
    text-align: right;
    width: 350px;
}

.app-settings-title-label {
    font-size: 11pt;
    font-weight: bold;
    margin-left: 2px;
}

.app-settings-title-input {
    border-radius: 5px;
    border-style: solid;
    border-width: 1px;
    font-size: 12pt;
    font-weight: bold;
    margin-bottom: 8px;
    margin-top: 10px;
    outline: none;
    padding: 12px;
    width: 350px;
}