.text-input {
  background-color: transparent;
  border: 1px dotted transparent;
  border-radius: 5px;
  font-size: 11pt;
  font-weight: bold;
  line-height: 1.5em;
  margin-top: 6px;
  outline: none;
  padding: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.text-value, 
.text-title {
  background-color: transparent;
  border: 1px dotted transparent;
  border-radius: 5px;
  cursor:default;
  height: auto;
  line-height: 1.5em;
  margin-top: 6px;
  outline: none;
  padding: 12px;
  padding-top: 8px;
  padding-bottom: 0px;
}

.text-value {
  font-size: 11pt;
  font-weight: bold;
}

.text-title {
  font-size: 16pt;
  font-weight: bold;
  padding-top: 0px;
}
