.user-list-container {
  background-color: transparent;
  border-radius: 11px;
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  padding: 2px;
  padding-bottom: 6px;
  padding-left: 4px;
  padding-top: 12px;
}

.user-list-header {
  background-color: transparent;
  display: flex;
  flex-direction: row;
  padding-right: 8px;
}

.user-list-label {
  font-size: 10pt;
  font-weight: normal;
  flex-grow: 1;
  margin-left: 12px;
  user-select: none;
}

.user-list-items {
  margin-left: 8px;
  margin-top: 10px;
}

.user-list-item {
  align-items: stretch;
  display: flex;
  flex-direction: row;
  user-select: none;
  width: 100%;
}

.user-list-item-summary {
  flex-grow: 1;
}

.user-list-item-forward {
  align-self: stretch;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 12px;
  width: 50px;
}

.user-list-modal-add {
  border-radius: 9px;
  cursor: pointer;
  font-size: 12pt;
  font-weight: bold;
  margin: 20px;
  margin-top: 40px;
  padding: 12px;
  text-align: center;
  width: auto;
}

.user-list-modal-noitems {
  font-size: 12pt;
  font-weight: bold;
}

.user-list-modal-overlay {
  align-items: flex-start;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  height: 100%;
  justify-content: flex-end;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10001;
}

.user-list-modal-panel {
  align-items: left;
  background: black;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-left: 20%;
  overflow-y: auto;
  padding: 20px;
  width: 400px;
}


.user-list-summary-button {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 11pt;
  font-weight: bold;
  height: 30px;
  justify-content: left;
  margin-bottom: 2px;
  margin-left: 14px;
  margin-top: 10px;
  width: auto;
}

.user-list-modal-summary-checkbox {
  cursor: pointer;
  margin-left: 12px;
  margin-top: 16px;
}

.user-list-modal-summary-row {
  display: flex;
  flex-direction: row;
  /*transform-origin: top left;
  transform: scale(0.8);
  width: 150%;*/
}

.user-list-modal-summary-wrapper {
  flex-grow: 1;
  padding-left: 10px;
  margin-top: 2px;
}

.user-list-done-button {
  border-radius: 7px;
  display: flex;
  font-size: 10pt;
  justify-content: center;
  margin-top: 20px;
  margin-left: 10px;
  padding: 12px;
  width: 100px;
}

/* Styles for small screens */
@media screen and (max-width: 768px) {

  .user-list-modal-panel {
      box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
      border-radius: 7px;
      left: 50%; /* Horizontally center the element */
      top: 50%; /* Vertically center the element */
      transform: translate(-50%, -50%); /* Correct centering by moving it 50% left and up */
      max-height: 95vh; /* Allow the dropdown to take up 95% of the screen height */
      overflow-y: auto; /* Enable scrolling when content exceeds the height */
      padding: 10px;
      position: fixed; /* Position the dropdown fixed relative to the viewport */
      width: 95vw; /* Take up 95% of the viewport width */
      z-index: 999; /* Ensure the dropdown is above other content */
      background-color: white; /* Ensure background is visible */
  }

}
