.app-settings-theme-column {
    flex-grow: 1;
}

.app-settings-theme-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 18px;
    width: 100%;
}

.app-settings-theme-label {
    font-size: 10pt;
    margin-left: 2px;
}