.calendar-container {
  background-color: transparent;
  height: calc(100vh - 50px);
  width: calc(440px + 40%);
}

.calendar-add-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}


/* Styles for small screens */
@media screen and (max-width: 768px) {

  .calendar-container {
    width: 100%;
  }

}