.account-about-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 0px;
    gap: 20px;
    height: 100%;
    justify-content: center;
    padding: 20px;
    scrollbar-color: transparent transparent;
    scrollbar-width: thin;
    width: 560px;
}

.account-about-top-section {
    margin-bottom: 20px;
}

.account-about-form {
    align-items: center;
    height: auto;
    overflow-y: auto;
    top: 170px;
    width: 350px;
}

@media (max-width: 768px) {

    .account-about-container {
        width: 100%;
    }

}