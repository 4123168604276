.forum-post-container {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    padding: 10px;
    padding-left: 20px;
}

.forum-post-header {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.forum-post-header-left {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
}   

.forum-post-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.forum-post-header {
    display: flex;
    flex-direction: row;
}

.forum-post-user {
    align-items: center;
    cursor: pointer;
    display: flex;
    font-size: 11pt;
    font-weight: bold;
    margin-left: 12px;
    width: auto;
}

.forum-post-gallery-wrapper {
    border-radius: 17px;
    height: 300px;
    margin-top: 18px;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    overflow: hidden;
    position: relative;
}

.forum-post-dot {
    display: flex;
    justify-content: space-around;
    width: auto;
}

.forum-post-user {
    align-items: center;
    cursor: pointer;
    display: flex;
    font-size: 10pt;
    font-weight: bold;
    margin-left: 12px;
    width: auto;
}

.forum-post-posted {
    font-weight: normal;
}

.forum-post-timestamp {
    align-items: center;
    display: flex;
    flex-direction: row;
    font-size: 9pt;
    font-weight: normal;
    margin-left: 8px;
}

.forum-post-title {
    font-size: 18pt;
    font-weight: bold;
    margin-top: 8px;
    padding-top: 12px;
    white-space: pre-wrap;
    /* Preserve spaces and line breaks */
    word-wrap: break-word;
    /* Prevent long words from overflowing */
}

.forum-post-content {
    font-size: 12pt;
    line-height: 1.5em;
    padding-bottom: 0px;
    padding-top: 8px;
    white-space: pre-wrap;
    /* Preserve spaces and line breaks */
    word-wrap: break-word;
    /* Prevent long words from overflowing */
}

.forum-post-comment-section {
    margin-left: 42px;
}

.forum-post-documents-wrapper {
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    padding-bottom: 6px;
    padding-right: 20px;
    padding-top: 12px;
}

.forum-post-object-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: left;
}

.forum-post-object {
    border-radius: 13px;
    margin: 20px;
    margin-left: 45px;
    padding: 8px;
    width: 440px;
}

@media (max-width: 600px) {

    .forum-post-gallery-wrapper {
        height: 200px;
    }

}