.form-footer-date-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 12px;
}

.form-footer-date-label {
  font-size: 8pt;
  user-select: none;
  width: 60px;
}

.form-footer-date-value {
  font-size: 8pt;
  user-select: none;
}

.form-footer-button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.form-footer-add-feature-button {
  background: transparent;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 11pt;
  font-weight: normal;
  margin-top: 30px;
  margin-bottom: 40px;
  padding: 10px 20px;
}

.form-footer-delete-button {
  background: transparent;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 11pt;
  font-weight: normal;
  margin-top: 30px;
  margin-bottom: 100px;
  padding: 10px 20px;
}