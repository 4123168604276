.account-password-container {
    display: flex;
    flex-direction: column;
    padding-left: 30px;
    padding-right: 30px;
    width: 100%;
}

.account-password-input {
    border-width: 0px;
    border-radius: 7px;
    box-sizing: border-box;
    padding: 13px;
    justify-content: center;
    margin-bottom: 14px;
    outline: none;
    width: 100%;
}

.account-password-error {
    font-size: 9pt;
    margin-left: 8px;
    margin-bottom: 20px;
    width: 100%;
}

.account-password-requirements {
    font-size: 9pt;
    text-align: left;
    line-height: 1.5em;
    margin-left: 16px;
    margin-bottom: 20px;
    width: 100%;
}

/* Styles for small screens */
@media screen and (max-width: 768px) {

    .account-password-container {
        padding-left: 0px;
        padding-right: 0px;
    }

    .account-password-error {
        margin-left: 0px;
    }

}