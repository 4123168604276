.app-bar-container {
    border-right: 1px solid;
    height: 100%;
    width: 63px;
}

@media (max-width: 768px) {

    .app-bar-container {
        border-right: 0px;
        border-radius: 11px;
        box-shadow: rgba(0,0,0,0.8) 0 2px 8px;
        padding-top: 20px;
    }

}