.table-container {
  height: calc(100vh - 115px);
  overflow: auto;
  width: 100%;
}

.table-table {
  border: 0;
  border-spacing: 0;
  margin: 0;
  width: auto;
}

.table-header-cell {
  border-bottom: 1px solid;
  display: flex;
  flex-direction: row;
  font-size: 10pt;
  font-weight: normal;
  justify-content: left;
  padding-bottom: 12px;
  padding-left: 12px;
  padding-right: 12px;
  text-wrap: nowrap;
  user-select: none;
  width: 100%;
}

.table-table th,
.table-table td {
  border: none;
  padding: 0;
  margin: 0;
}

.table-header-left {
  border-bottom: 1px solid;
  height: 28px;
  width: 40px;
}

.table-header-left-button {
  align-items: top;
  display: flex;
  flex-direction: row;
  height: 28px;
  justify-content: center;
  padding-left: 8px;
}

.table-row-left {
  align-items: center;
  border-bottom: 1px solid;
  border-right: 1px solid;
  bottom: 0;
  cursor: pointer;
  display: flex;
  height: 42px;
  justify-content: center;
  left: 0;
  padding-left: 10px;
  position: absolute;
  top: 0;
  width: 40px;
}