.calendar-week-container {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.calendar-week-days {
  display: flex;
  height: 40px;
  justify-content: space-around;
  margin-left: 60px;
  padding: 10px 0;
  border-bottom: 1px solid #242424;
  z-index: 1;
}

.calendar-week-day {
  font-size: 10pt;
  flex-grow: 1;
  text-align: center;
}

.calendar-week-hour-block {
  display: flex;
  flex-direction: row;
  height: 70px;
}

.calendar-week-hour-label {
  border-right: 1px solid;
  font-size: 12px;
  margin-top: -7px;
  padding-right: 10px;
  text-align: right;
  user-select: none;
  width: 60px;
}

.calendar-week-scroller {
  position: relative; /* Allows for absolute positioning within */
  margin-top: 20px;
  width: 100%;
}

.calendar-week-hour-content {
  flex-grow: 1;
  cursor: pointer;
  border-right: 1px solid;
  border-bottom: 1px solid;
}

.calendar-week-events-overlay {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2; /* Ensure this is above hour blocks */
  pointer-events: none; /* Review based on your needs */
}

.calendar-week-event {
  border-radius: 7px;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  left: 70px;
  overflow: hidden;
  padding: 5px;
  padding-top: 2px;
  pointer-events: auto;
  position: absolute;
  right: 0;
  user-select: none;
  width: 22%;
  z-index: 3;
}

.calendar-week-event-object {
  font-size: 8pt;
  font-weight: bold;
  margin-bottom: 4px;
}

.calendar-week-event-field {
  font-size: 7pt;
  font-weight: normal;
}
