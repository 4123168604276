.chat-post-form-container,
.chat-post-form-container-mobile {
    border-top: 1px solid;
    display: flex;
    flex-direction: column;
    height: auto;
    bottom: 0px;
    padding: 6px;
    padding-top: 14px;
    width: 100%;
    z-index: 1;
}

.chat-post-form-container {
    padding-bottom: 6px;
}

.chat-post-form-container-mobile {
    margin-bottom: 6px;
}

.chat-post-form-wrapper {
    display: flex;
    flex-direction: row;
    height: auto;
    width: 100%;
}

.chat-post-form-left {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 15px;
    width: auto;
}

.chat-post-form-center {
    flex-grow: 1;
    flex-shrink: 1;
    padding-right: 8px;
}

.chat-post-form-right {
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    justify-content: space-around;
    padding-top: 4px;
    width: auto;
}

.chat-post-form-attachments {
    display: flex;
    flex-direction: row;
    height: 0;
    overflow: hidden;
    padding-left: 70px;
    transition: height 0.3s ease;
}

.chat-post-form-attachments.visible {
    height: 40px;
}

.chat-post-form-attachments.hidden {
    height: 0;
}

.chat-post-form-attachment-button {
    align-items: center;
    border-radius: 7px;
    cursor: pointer;
    display: flex;
    height: 34px;
    justify-content: center;
    margin-right: 12px;
    text-align: center;
    width: 34px;
}

.chat-post-form-document-filename {
    font-size: 10pt;
    font-weight: bold;
}

@media (max-width: 600px) {

    .chat-post-form-button {
        font-size: 10pt;
        height: 32px;
    }

}