.admin-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-bottom: 200px;
    text-align: center;
    width: 100%;
}

.admin-tab-item {
    cursor: pointer;
    font-weight: bold;
}

.admin-tab-item.active {
    cursor: pointer;
    font-weight: bold;
}

.admin-tabs {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 30px;
    padding: 20px;
    width: 100%;
}

.admin-content {
    width: 100%;
}

.admin-prompt {
    border-radius: 17px;
    font-size: 9pt;
    margin-top: 6px;
    outline: none;
    padding: 12px;
    padding-top: 8px;
    padding-bottom: 8px;
    height: 500px;
    width: 90%;
}

.admin-save-button {
    border-radius: 9px;
    height: 40px;
    margin-left: 12px;
    margin-top: 20px;
    padding-left: 16px;
    padding-right: 16px;
    width: 170px;
  }