.event-object-form-container {
    display: flex;
    flex-direction: column;
    margin: 0px;
    height: auto;
    position: relative;
    width: 440px;
}

.event-object-form-container-mobile {
    display: flex;
    flex-direction: column;
    margin: 0px;
    height: auto;
    overflow-y: auto;
    position: relative;
    width: 100%;
}