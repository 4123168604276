.checklist-add-button-container {
  background-color: transparent;
  border: 0px solid transparent;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 30px;
  justify-content: left;
  margin-left: 46px;
  margin-bottom: 2px;
  width: auto;
}

.checklist-add-button-image {
  width: 40px;
}

.checklist-add-button-text {
  font-size: 11pt;
  font-weight: bold;
  padding-top: 3px;
}