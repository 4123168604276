.select-container {
    position: relative;
    user-select: none;
    width: 100%;
}

.select-trigger {
    background-color: transparent;
    border: 1px dotted transparent;
    border-radius: 5px;
    cursor: pointer;
    font-size: 11pt;
    font-weight: bold;
    margin-top: 6px;
    outline: none;
    padding: 12px;
    padding-bottom: 8px;
    padding-left: 11px;
    padding-top: 8px;
}

.select-options {
    border-radius: 7px;
    border-width: 0px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    left: 0;
    max-height: 400px;
    overflow-y: auto;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 999;
}

/* Styles for mobile */
.select-options.mobile {
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    border-radius: 7px;
    border-width: 0px;
    left: 50%;
    max-height: 95vh;
    overflow-y: auto;
    padding: 10px;
    position: fixed;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 95vw;
}

.select-option {
    cursor: pointer;
    padding: 10px;
}

