
.calendar-day-container {
  position: relative; /* Allows for absolute positioning within */
  margin-top: 20px;
  width: 100%;
}

.calendar-day-hour-block {
  display: flex;
  flex-direction: row;
  height: 70px;
  z-index: 1;
}

.calendar-day-hour-label {
  border-right-width: 1px;
  border-right-style: solid;
  font-size: 12px;
  margin-top: -7px;
  padding-right: 10px;
  text-align: right;
  user-select: none;
}

.calendar-day-hour-contents {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  flex-grow: 1;
  height: 1px;
}

.calendar-day-events-overlay {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2; /* Ensure this is above hour blocks */
  pointer-events: none; /* Review based on your needs */
}

.calendar-day-event {
  border: 1px solid;
  border-radius: 9px;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  left: 70px;
  padding: 10px;
  padding-top: 9px;
  pointer-events: auto;
  position: absolute;
  right: 0;
  user-select: none;
  width: 80%;
  z-index: 3;
  overflow: hidden; /* Hide overflow */
  position: relative; /* Needed for the fade div */
}

.calendar-day-event-details {
  max-height: 100px; /* Adjust based on your requirement */
  overflow: hidden;
}

.calendar-day-event-title {
  font-size: 9pt;
  font-weight: bold;
  margin-bottom: 4px;
}

.calendar-day-event-time {
  font-size: 8pt;
  font-weight: normal;
}

.calendar-day-event-detail {
  font-size: 8pt;
  font-weight: normal;
  margin-top: 6px;
}