.event-field-selector-container {
    display: flex;
    flex-direction: column;
    margin: 0px;
    height: auto;
    position: relative;
    width: 100%;
}

.event-field-selector-add-wrapper {
    display: flex;
    flex-direction: row;
    padding-left: 6px;
    width: 100%;
}

.event-field-selector-add-button {
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    height: 20px;
    justify-content: center;
    margin-top: 1px;
    width: 20px;
}

.event-field-selector-add-label {
    align-items: center;
    cursor: pointer;
    display: flex;
    font-size: 11pt;
    font-weight: bold;
    flex-grow: 1;
    padding-left: 12px;
}