.objects-container {
    border-right-width: 1px;
    border-right-style: solid;
    display: flex;
    flex-direction: column;
    position: relative;
}

.objects-container-mobile {
    border-right-width: 0px;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 50px);
    padding-top: 10px;
    position: relative;
    width: 100%;
}

.objects-add-button {
    position: absolute;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
}

.objects-table {
    background-color: transparent;
    overflow: auto;
    padding-top: 22px;
    padding-left: 0px;
    padding-right: 10px;
    flex-grow: 1;
    width: 100%;
}

.objects-list {
    background-color: transparent;
    overflow-y: auto;
    padding-top: 22px;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
}

.objects-divider {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    margin-bottom: 9px;
}

.objects-groups-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
}

.objects-group-title {
    align-items: top;
    display: flex;
    flex-direction: row;
    font-size: 12pt;
    font-weight: bold;
    height: 36px;
    padding-left: 20px;
    padding-top: 10px;
}

.objects-group-row {
    align-items: center;
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    width: 100%;
}

@media (max-width: 768px) {

    .objects-add-button {
        position: absolute;
        bottom: 20px;
        right: 20px;
        z-index: 1000;
    }

    .objects-add-button {
        position: fixed; /* Changed from absolute to fixed */
        bottom: 20px;
        right: 20px;
        z-index: 1000; /* Ensure it's above other content */
    }

    .objects-list {
        height: calc(100vh - 90px);
        padding-left: 0px;
        padding-right: 0px;

        /* Hide scrollbar for WebKit browsers (Chrome, Safari) */
        -webkit-overflow-scrolling: touch; /* Smooth scrolling for touch devices */
    }

    .objects-list::-webkit-scrollbar {
        display: none; /* Hide the scrollbar for WebKit browsers */
    }
    
    /* Hide scrollbar for Firefox */
    .objects-list {
        scrollbar-width: none; /* Hide the scrollbar in Firefox */
        -ms-overflow-style: none; /* Hide the scrollbar in Internet Explorer 10+ */
    }

}