.channel-edit-label {
    font-size: 10pt;
    margin-top: 14px;
    margin-left: 6%;
    margin-right: 6%;
    width: 90%;
}

.channel-edit-input {
    border-style: solid;
    border-radius: 5px;
    border-width: 1px;
    margin-bottom: 18px;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 20px;
    outline: none;
    padding: 12px;
    width: 90%;
}

.channel-edit-buttons {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    width: 100%;
}

.channel-edit-button {
    background: transparent;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 10pt;
    font-weight: bold;
    padding: 10px 20px;
}