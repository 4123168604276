.object-container {
    border-color: transparent;
    border-right-style: solid;
    border-right-width: 5px;
    cursor: pointer;
    flex-grow: 1;
    flex-shrink: 0; /* Prevent objects from shrinking */
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px;
    overflow: hidden;
    position: relative;
    user-select: none;
    width: 100%;
}

.object-item {
    overflow: hidden;
    position: absolute;
}