.canvas-container {
    align-items: center;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 129px);
    justify-content: left;
    overflow: auto;
    padding: 14px;
    padding-left: 18px;
    padding-right: 18px;
    padding-top: 10px;
}

.canvas-top {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
}

.canvas-bottom {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 100px;
    justify-content: center;
    margin-bottom: 100px;
    width: 100%;
}

.canvas-wrapper {
    height: auto;
    margin-top: 18px;
    overflow: show;
    position: relative;
    width: 100%;
}