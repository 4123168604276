.app-users-user {
    border-width: 2px;
    border-style: solid;
    border-color: transparent;
    border-radius: 9px;
    cursor: pointer;
    flex-grow: 1;
    margin-bottom: 10px;
    overflow: hidden;
    position: relative;
    user-select: none;
    height: 68px;
}

.app-users-name {
    position: absolute;
    font-size: 12pt;
    font-weight: bold;
    margin-left: 4px;
    margin-top: 10px;
}

.app-users-role {
    position: absolute;
    font-size: 12px;
    font-weight:  normal;
    margin-left: 4px;
    margin-top: 36px;
}

.app-users-divider {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    margin-bottom: 9px;
}