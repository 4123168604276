.mobile-container {
    align-items: center;
    display: flex;
    height: 100vh;
    flex-direction: column;
    position: relative;
    width: 100%;
}

.mobile-title-bar {
    width: 100%;
}

.mobile-center {
    box-shadow: rgba(0, 0, 0, 0.2) 0 2px 8px;
    display: flex;
    height: 100vh;
    overflow: hidden;
    position: relative;
    width: 100%;
}

.mobile-center-mobile {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    box-shadow: none;
    width: 100%;
}

.mobile-content {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.mobile-summaries {
    height: 100vh;
    width: 100%;
}

.mobile-menu-mask {
    background-color: rgba(0, 0, 0, 0.5);
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 4;
}

.mobile-menu {
    position: absolute;
    height: 100%;
    left: -100%;
    top: 0px;
    transition: left 0.3s ease;
    width: 250px;
    z-index: 1000;
}

.mobile-menu.visible {
    left: 0;
}

.mobile-title {
    display: block;
    width: 100%;
}

@media (max-width: 768px) {

    .mobile-title-bar {
        display: none;
    }

    .mobile-title {
        display: none;
    }

    .mobile-center {
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        box-shadow: none;
        width: 100%;
    }

}