.form-toolbar-container {
    align-content: top;
    box-sizing: border-box;
    display: flex;
    height: 30px;
    justify-content: space-between;
    margin-bottom: 16px;
    margin-top: 6px;
    padding-left: 16px;
    padding-right: 16px;
}

.form-toolbar-left {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    font-size: 16pt;
    font-weight: bold;
    justify-content: left;
    overflow: hidden;
    padding-left: 8px;
    text-wrap: nowrap;
    user-select: none;
    width: 70%;
}

.form-toolbar-right {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: right;
    padding-right: 8px;
    width: 10%;
}

.form-toobar-button {
    height: 20px;
    margin-right: 20px;
    width: 20px;
}

.form-toolbar-close-button {
    cursor: pointer;
    padding-right: 4px;
    padding-top: 4px;
}