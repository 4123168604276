.app-user-profile-container {
    display: flex;
    flex-direction: column;
    margin: 0px;
    height: calc(100vh - 50px);
    overflow-y: auto;
    padding: 20px;
    position: relative;
    scrollbar-color: transparent transparent;
    scrollbar-width: thin;
    width: 40%;
}

/* Scrollbar Classes*/
.app-user-profile-container::-webkit-scrollbar {
    width: 8px;
}

.app-user-profile-container::-webkit-scrollbar-thumb {
    background-color: #555;
    border-radius: 4px;
}

.app-user-profile-container::-webkit-scrollbar-thumb:hover {
    background-color: transparent;
}

.app-user-profile-name {
    font-size: 22px;
    font-weight: bold;
    margin-top: 20px;
}

.app-user-profile-joined {
    font-size: 14px;
    font-weight: normal;
    margin-top: 10px;
}

.app-user-profile-delete-button-container {
  display: flex;
  flex-direction: row;
  justify-content: left;
  width: 100%;
}

.app-user-profile-delete-button {
    border: none;
    border-radius: 9px;
    cursor: pointer;
    font-size: 10pt;
    font-weight: normal;
    margin-top: 50px;
    margin-bottom: 100px;
    padding: 10px 20px;
  }

@media (max-width: 768px) {

    .app-user-profile-container {
        border-right-width: 0px;
        border-top-width: 0px;
        margin-top: 0px;
        min-height: calc(100vh - 50px);
        padding-top: 4px;
        width: 100%;
    }

}