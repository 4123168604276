.conversation-summary-container {
    border-width: 2px;
    border-style: solid;
    border-color: transparent;
    border-radius: 9px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    height: auto;
    min-height: 68px;
    margin-bottom: 10px;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px;
    overflow: hidden;
    position: relative;
    user-select: none;
}

.conversation-summary-left {
    padding: 8px;
    width: 46px;
}

.conversation-summary-right {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: auto;
}

.conversation-summary-top {
    display: flex;
    flex-direction: row;
}

.conversation-summary-name {
    flex-grow: 1;
    font-size: 12pt;
    font-weight: bold;
    margin-left: 10px;
    margin-top: 10px;
}

.conversation-summary-elapsed {
    align-items: center;
    display: flex;
    font-size: 9pt;
    margin-top: 10px;
}

.conversation-summary-last-message {
    font-size: 14px;
    font-weight: normal;
    margin-left: 10px;
    margin-top: 8px; /* Adjusted margin to separate from name */
}

.conversation-summary-last-read {
    font-size: 10pt;
    font-weight: normal;
}  

.conversation-summary-last-unread {
    font-size: 10pt;
    font-weight: bold;
}
