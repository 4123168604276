.add-menu-item-button-container {
    bottom: 0;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    margin-bottom: 8px;
    margin-left: 16px;
    margin-top: 9px;
}

.add-menu-item-button-image {
    height: 11px;
    margin-left: 4px;
    margin-bottom: 1px;
    opacity: 0.2;
}

.add-menu-item-button-text {
    font-size: 11pt;
    font-weight: bold;
    margin-bottom: 5px;
    margin-left: 7px;
    margin-right: 8px;
    opacity: 0.2;
    user-select: none;
    white-space: nowrap;
}

@media (max-width: 768px) {

    .add-menu-item-button-container {
        margin-top: 10px;
    }

    .add-menu-item-button-text {
        font-size: 13pt;
    }

}