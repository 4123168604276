.home-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: calc(100vh);
}

.home-logo {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 70px;
}

.home-tagline {
    display: flex;
    font-size: 28pt;
    font-weight: 400;
    justify-content: center;
    opacity: 1;
    display: none;
}

.home-title-bar {
    display: flex;
    justify-content: center;
    width: 800px;
}

.home-prompt {
    margin: auto;
    margin-bottom: 68px;
    width: 800px;
}

.home-apps {
    margin: auto;
    width: 840px;
}

.home-scroller {
    height: calc(100vh - 50px);
    overflow-y: auto;
    width: 960px;
}

.home-title-bar-wrapper {
    align-items: center;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    height: 50px;
    position: relative;
    top: 0;
    user-select: none;
    width: 960px;
}

/* Media Query for screens narrower than 960px */
@media (max-width: 768px) {

    .home-apps {
        margin: auto;
        width: 100%;
    }

    .home-logo {
        margin-top: 40px;
        margin-bottom: 20px;
    }

    .home-prompt {
        margin-bottom: 35px;
        padding-left: 10px;
        padding-right: 10px;
        width: 100%;
    }

    .home-scroller {
        height: calc(100vh - 50px);
        width: 100%;
    }

    .home-tagline {
        font-size: 12pt;
    }
}