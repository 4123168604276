.user-fields-container {
    display: flex;
    flex-direction: column;
    height: 450px;
    margin-bottom: 18px;
    overflow-y: auto;
    width: 100%;
}

.user-fields-title {
    font-size: 11pt;
    font-weight: bold;
    margin-left: 2px;
}

.user-fields-required {
    font-size: 11pt;
}

.user-fields-select {
    border: 0px;
    border-radius: 9px;
    font-size: 11pt;
    padding: 10px;
    padding-right: 14px;
}