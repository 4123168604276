.event-object-selector-wrapper {
    display: flex;
    flex-direction: row;
    padding-left: 6px;
    width: 100%;
}

.event-object-selector-button {
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    height: 20px;
    justify-content: center;
    margin-top: 1px;
    width: 20px;
}

.event-object-button-add-label {
    align-items: center;
    cursor: pointer;
    display: flex;
    font-size: 11pt;
    font-weight: bold;
    flex-grow: 1;
    padding-left: 12px;
}

.calendar-event-checklist-selector {
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  margin-left: 5%;
  margin-right: 5%;
  margin-bottom: 30px;
  margin-top: 30px;
  outline: none;
  padding: 12px;
  width: 90%;
}

.calendar-event-list-wrapper {
    background-color: transparent;
    border-radius: 11px;
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
    margin-top: 14px;
    padding: 2px;
    padding-bottom: 6px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 12px;
}

.event-object-button-group {
    margin-bottom: 4px;
    margin-top: 4px;
}

.event-object-button-group-title {
    font-weight: bold;
    margin-bottom: 10px;
}

.event-object-button-entry-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
}

.event-object-button-entry-title {
    font-size: 12pt;
    font-weight: bold;
    padding: 8pt;
    padding-left: 20pt;
}