.forum-container {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: auto;
}

.forum-container-mobile {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.forum-left {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  width: 540px;
}

.forum-right {
  border-left: 1px solid;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  width: 40%;
}

.forum-right-mobile {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 100px);
  height: 100%;
  width: 100%;
}

.forum-add-button {
  position: absolute;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

.forum-post-form-container{
  border-top: 1px solid;
  border-bottom: 1px solid;
  display: flex;
  flex-direction: column;
  height: auto;
  bottom: 0px;
  padding: 6px;
  transition: height 0.3s ease;
  width: 100%;
  z-index: 1;
}

.forum-post-timestamp {
  font-size: 9pt;
  font-weight: normal;
  margin-left: 8px;
}

@media screen and (max-width: 768px) {

  /* Go wide since we're not showing users */
  .forum-left {
    width: 100%;
  }

}




.forum-feed {
  border-right-width: 1px;
  border-right-style: solid;
  flex-grow: 1;
  overflow-y: scroll;
  width: 600px;
}

.forum-feed-mobile {
  height: calc(100vh - 50px);
  overflow-y: scroll;
  width: 100%;
}

.forum-headline {
  flex-shrink: 1;
  font-size: 11pt;
  font-weight: normal;
  height: auto;
  line-height: 1.5em;
  margin-left: 20px;
  margin-right: 20px;
  width: 100%;
}

.forum-headline-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.forum-headline-remove-container {
  cursor: pointer;
  width: 40px;
}

.forum-divider {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  margin: 20px;
}

.forum-token {
  cursor: pointer;
  font-size: 11pt;
  font-weight: bold;
  user-select: none;
}

.forum-post-text {
  font-size: 11pt;
}

/* Styles for small screens */
@media screen and (max-width: 768px) {}