.form-delete-confirmation-text {
    display: flex;
    flex-direction: row;
    font-size: 11pt;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 12px;
    text-align: center;
    width: 100%;
  }