.app-settings-icon-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 18px;
    width: 100%;
}

.app-settings-icon-top {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.app-settings-icon-bottom {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 50px;
}

.app-settings-icon-percentage {
    font-size: 11pt;
    font-weight: bold;
    padding: 20px;
}

.app-settings-icon-top-column {
    flex-grow: 1;
}