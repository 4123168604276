.gallery-outer-container {
  display: flex;
  flex-direction: column;
  height: auto;
  margin-bottom: 12px;
}

.gallery-container {
  align-items: center;
  border-width: 1px;
  border-style: dotted;
  border-radius: 11px;
  display: flex;
  height: auto;
  justify-content: center;
  margin-bottom: 4px;
  min-height: 200px;
  overflow: hidden;
  position: relative;
}

.gallery-add-button {
  align-items: center;
  border-radius: 7px;
  bottom: 10px;
  cursor: pointer;
  display: flex;
  height: 36px;
  padding: 10px;
  position: absolute;
  right: 10px;
  width: 36px;
  z-index: 2;
}

.gallery-field-menu {
  align-items: center;
  border-radius: 7px;
  cursor: pointer;
  display: flex;
  height: 36px;
  justify-content: center;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 36px;
  z-index: 2;
}

.gallery-delete-button {
  align-items: center;
  border-radius: 7px;
  bottom: 50px;
  cursor: pointer;
  display: flex;
  height: 36px;
  justify-content: center;
  position: absolute;
  right: 10px;
  width: 36px;
  z-index: 2;
}

.gallery-image {
  height: 100%;
  object-fit: cover;
  object-position: center;
  width: 100%;
}

.gallery-image-count {
  border-radius: 5px;
  bottom: 10px;
  font-size: 10pt;
  left: 10px;
  padding: 5px 10px;
  position: absolute;
  z-index: 2;
}

.gallery-nav-button {
  align-items: center;
  border-radius: 7px;
  cursor: pointer;
  display: flex;
  height: 36px;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 36px;
  z-index: 2;
}

.gallery-wrapper {
  align-items: center;
  border-radius: 11pt;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.previous-button {
  left: 10px;
}

.next-button {
  right: 10px;
}