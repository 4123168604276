.lookup-editor-overlay {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10000;
}

.lookup-editor-content {
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  max-height: 80vw;
  min-width: 300px;
  max-width: 80vw;
  overflow: auto;
  padding: 10px;
  user-select: none;
  transform: scale(0.9); /* Initial scale for animation */
}

.lookup-editor-body {
  padding-bottom: 12px;
  padding-top: 12px;
}

.lookup-editor-label {
  font-size: 11pt;
  font-weight: bold;
}

.lookup-editor-options-wrapper {
  margin-bottom: 12px;
  margin-top: 12px;
  max-height: 300px;
  overflow-y: scroll;
}

.lookup-editor-option-item {
  padding: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 11pt;
  font-weight: bold;
}

.lookup-editor-option-text {
  flex-grow: 1;
}

.lookup-editor-remove-container {
  padding-top: 2px;
  width: 14px;
}

.lookup-editor-add-container {
  display: flex;
  flex-direction: row;
  gap: 12px;
  margin-top: 10px;
}

.lookup-editor-add-text-container {
  flex-grow: 1;
}

.lookup-editor-add-input {
    border-width: 1px;
    border-style: solid;
    border-radius: 9px;
    font-size: 10pt;
    padding: 12px;
    margin-top: 10px;
    margin-bottom: 18px;
    outline: none;
    width: 100%;
}

.lookup-editor-add-button-container {
  display: flex;
  height: 40px;
  margin-top: 10px;
  align-items: center;
  justify-content: center;
  width: 36px;
}

.lookup-editor-save-button {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 10pt;
  font-weight: bold;
  justify-content: center;
  padding: 8px;
  width: 100%;
}