.app-item-business-model {
    font-size: 9pt;
    font-weight: normal;
    line-height: 1.3em;
    margin-top: 2px;
    margin-left: 16px;
    margin-right: 8px;
    user-select: none;
}

.app-item-container {
    align-items: top;
    border-width: 1px;
    border-style: solid;
    border-radius: 16px;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 12px;
    padding-left: 20px;
    padding-right: 20px;
    transition: background-color 0.2s;
    user-select: none;
}

.app-item-description {
    font-size: 9pt;
    font-weight: normal;
    line-height: 1.3em;
    margin-top: 6px;
    margin-left: 4px;
    margin-right: 8px;
    margin-bottom: 10px;
    user-select: none;
}

.app-item-footer {
    align-items: center;
    display: flex;
    flex-direction: row;
}

.app-item-left {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
}

.app-item-logo {
    height: 20px;
    margin-left: 6px;
    margin-top: 7px;
    margin-right: 6px;
}

.app-item-title {
    font-size: 13pt;
    font-weight: bold;
    margin-top: 6px;
    margin-bottom: 5px;
    margin-left: 0px;
    margin-right: 8px;
    user-select: none;
}

.app-item-title-version {
    display: flex;
    flex-direction: row;
}

.app-item-top {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.app-item-version {
    font-size: 7pt;
    font-weight: normal;
    margin-top: 12px;
    user-select: none;
    white-space: nowrap;
}

.app-item-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

/* Styles for small screens */
@media screen and (max-width: 768px) {

    .app-item-container {
        padding: 12px;
    }

    .app-item-left {
        align-items: center;
        flex-direction: column;
    }

    .app-item-logo {
        height: 30px;
        margin-bottom: 6px;
    }

    .app-item-description {
        display: none;
        font-size: 7pt;
        text-align: center;
    }

    .app-item-title {
        font-size: 8pt;
        font-weight: normal;
        margin-top: 6px;
        text-align: center;
    }

    .app-item-title-version {
        align-items: center;
        flex-direction: column;
    }

    .app-item-version {
        font-size: 6pt;
        margin-bottom: 8px;
        margin-left: 2px;
        margin-top: 0px;
    }

}