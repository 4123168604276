.designer-container {
    display: flex;
    flex-direction: row;
    height: 560px;
    margin-top: 10px;
    overflow: hidden;
}

.designer-fields {
    display: flex;
    flex-direction: column;
    width: 230px;
}

.designer-canvas-wrapper {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
}

.designer-canvas-center {
    margin-left: auto;
    margin-right: auto;
}

.designer-properties {
    display: flex;
    flex-direction: column;
    width: 230px;
}