.new-model-modal-wrapper {
    display: flex;
    flex-direction: column;
}

.new-model-modal-container {
    display: flex;
    flex-direction: row;
    margin: 10px;
    margin-bottom: 10px;
    margin-top: 10px;
}

.new-model-modal-right {
    width: 250px;
}

.new-model-modal-left {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.new-model-modal-label {
    font-size: 10pt;
    margin-left: 2px;
    margin-top: 12px;
}

.new-model-modal-label-description {
  font-size: 8pt;
  line-height: 1.5em;
  margin-top: 14px;
  margin-bottom: 10px;
}

.new-model-modal-label-description li {
    margin-left: 3px;
}

.new-model-modal-divider {
    font-size: 12px;
    font-weight: bold;
    margin-left: 32px;
    margin-right: 32px;
    width: 2px;
    height: 400px;
}

.new-model-modal-templates {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    flex-grow: 1;
    height: 400px;
    margin-top: 16px;
    overflow-y: auto;
}

.new-model-modal-buttons {
    margin-top: 8px;
    text-align: center;
    width: 100%;
}

.new-model-modal-button {
    background: transparent;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 10pt;
    font-weight: bold;
    padding: 10px 20px;
    width: 200px;
  }