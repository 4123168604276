.number-container {
  background-color: transparent;
  border-radius: 11px;
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  padding: 2px;
  padding-bottom: 6px;
  padding-left: 4px;
  padding-top: 12px;
}

.number-input {
  background-color: transparent;
  border: 1px dotted transparent;
  border-radius: 5px;
  font-size: 12pt;
  font-weight: bold;
  margin-top: 6px;
  outline: none;
  padding: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.number-label {
  font-size: 10pt;
  margin-left: 12px;
  user-select: none;
}

.number-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 8px;
}