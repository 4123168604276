.mobile-header-container {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 50px;
    min-height: 60px;
    width: 100%;
}

.mobile-header-left {
    align-items: center;
    display: flex;
    height: 100%;
    padding-left: 20px;
    user-select: none;
    width: 25%;
}

.mobile-header-center {
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 100%;
    justify-content: center;
    user-select: none;
    width: 50%;
}

.mobile-header-right {
    align-items: center;
    display: flex;
    height: 100%;
    padding-right: 20px;
    justify-content: right;
    user-select: none;
    width: 25%;
}

.mobile-header-app-title {
    font-family: 'Open Sans', 'Roboto', 'Arial';
    font-size: 10pt;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    padding-top: 4px;
}