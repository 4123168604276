.mobile-logo-container {
    align-items: baseline;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    height: 100%;
}

.mobile-logo-symbol {
    height: 22px;
    margin-top: 1px;
}

.mobile-logo {
    align-items: center;
    display: flex;
    flex-direction: row;
    font-family: 'Open Sans', 'Roboto', 'Arial';
    font-size: 13pt;
    font-weight: bold;
    height: 50px;
}