.event-remove-button {
    align-items: center;
    border-radius: 7px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    font-size: 10pt;
    font-weight: bold;
    height: 40px;
    justify-content: center;
    padding: 12px;
    width: 100px;
}