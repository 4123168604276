.checkbox-preview-container {
  align-items: center;
  background-color: transparent;
  border-radius: 11px;
  display: flex;
  flex-direction: row;
  margin-top: 12px;
  padding-bottom: 16px;
  padding-left: 14px;
  padding-top: 14px;
  text-align: left;
}

.checkbox-preview-image {
  width: 22px;
}

.checkbox-preview-label {
  font-size: 12pt;
  font-weight: bold;
  margin-left: 11px;
  width: 100%;
}