.channel-type-item-container {
    display: flex;
    flex-direction: row;
    gap: 12px;
    height: auto;
    padding: 12px;
    transition: background-color 0.2s;
}

.channel-type-item-icon {
    height: 100%;
}

.channel-type-item-text {
    align-items: center;
    display: flex;
    font-size: 10pt;
    font-weight: bold;
    justify-content: left;
    width: 100%;
}