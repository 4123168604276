.context-menu-popup-container {
  position: absolute;
  border-radius: 9px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  padding-top: 8px;
  padding-bottom: 8px;
  right: 5px;
  z-index: 1001;
  max-height: 80vh; /* Limit the maximum height */
  overflow-y: auto;  /* Enable vertical scrolling if content exceeds the height */
}
