.forum-post-form-video-button-container {
    align-items: center;
    border-radius: 7px;
    cursor: pointer;
    display: flex;
    height: 34px;
    justify-content: center;
    margin-right: 12px;
    text-align: center;
    width: 34px;
}