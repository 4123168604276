.account-about-photo-container {
    border-radius: 50%;
    border-width: 6px;
    border-style: solid;
    bottom: 0;
    display: flex; 
    flex-direction: column;
    height: 150px;
    left: 20px;
    overflow: hidden;
    width: 150px;
    z-index: 500;
}

.account-about-photo-wrapper {
    display: flex;
    border-radius: 15px;
    flex-direction: row;
    height: 100%;
    justify-content: center;
    overflow: hidden;
    width: 100%;
}

.account-about-photo {
    height: 100%;
    object-fit: cover;
    object-position: center;
    width: 100%;
}

.account-about-photo-default {
    cursor: hand;
    height: 80%;
    margin-top: 5px;
    opacity: 0.02;
}