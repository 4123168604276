.app-user-photo-container {
    border-radius: 17px;
    display: flex; 
    flex-direction: column;
    max-height: 500px;
    overflow: hidden;
    width: 100%;
    z-index: 500;
}

.app-user-photo-wrapper {
    display: flex;
    border-radius: 15px;
    flex-direction: row;
    height: 100%;
    justify-content: center;
    overflow: hidden;
    width: 100%;
}

.app-user-photo {
    height: 100%;
    object-fit: cover;
    object-position: center;
    width: 100%;
}

.app-user-photo-default {
    cursor: hand;
    height: 80%;
    margin-top: 5px;
    opacity: 0.02;
}