.checklist-item-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
  width: 100%;
}

.checklist-item-drag-handle {
  cursor: pointer;
  padding: 8px;
  width: 30px;
}

.checklist-item-checkbox {
  cursor: pointer;
  padding: 8px;
}

.checklist-item-middle {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: auto;
}

.checklist-item-title {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 12pt;
  font-weight: bold;
  padding-left: 8px;
  padding-top: 8px;
}

.checklist-item-instructions {
  align-items: center;
  cursor: pointer;
  font-size: 10pt;
  font-weight: normal;
  flex-grow: 1;
  padding-left: 8px;
  padding-top: 8px;
}

.checklist-item-remove-container {
  align-items: top;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 12px;
  padding-top: 12px;
}