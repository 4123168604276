.app-settings-font-container {
    cursor: pointer;
    font-size: 11pt;
    height: 430px;
    overflow-y: auto;
    text-align: left;
    width: 100%;
}

.app-settings-font-item {
    border-radius: 11px;
    padding: 12px;
    user-select: none;
}

.app-settings-font-label {
    font-size: 10pt;
}

.app-settings-font-header-label {
    font-size: 11pt;
    font-weight: bold;
    margin-bottom: 10px;
    margin-left: 2px;
}

.app-settings-font-value {
    font-size: 13pt;
    padding-bottom: 18px;
    padding-top: 12px;
}

.app-settings-font-loading {
    font-size: 10pt;
}

@media (max-width: 768px) {

    .app-settings-font-value {
        font-size: 11pt;
    }
  
  }
