.user-list-preview-container {
  background-color: transparent;
  border-radius: 11px;
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  padding: 2px;
  padding-bottom: 6px;
  padding-left: 4px;
  padding-top: 12px;
}

.user-list-preview-wrapper {
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 9px;
  display: flex;
  flex-direction: column;
  height: 200px;
  justify-content: center;
  overflow: hidden;
  margin-top: 10px;
}

.user-list-preview-label {
  font-size: 10pt;
  margin-left: 12px;
}

.user-list-preview-header {
  padding: 4px;
  padding-left: 6px;
  padding-bottom: 8px;
  display: flex;
  flex-direction: row;
  width: 100%;
}

.user-list-preview-content {
  flex-grow: 1;
  height: 200px;
  width: 100%;
}

.user-list-preview-title {
  font-size: 11pt;
  flex-grow: 1;
  margin-top: 3px;
  margin-left: 4px;
}

.user-list-preview-title {
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 7px;
  font-size: 12pt;
  font-weight: bold;
  padding: 2px;
  outline: none;
}