.video-gallery-video {
    height: 100%;
    object-fit: cover;
    object-position: center;
    width: 100%;
}

.video-gallery-left {
    height: 100%;
    margin-right: 4px;
    object-fit: cover;
    object-position: center;
    width: 50%;
}

.video-gallery-right {
    display: flex;
    flex-direction: column;
    object-fit: cover;
    object-position: center;
    height: 100%;
    width: 50%;
}

.video-gallery-right-top {
    object-fit: cover;
    object-position: center;
    width: 100%;
    flex-grow: 1;
    height: 150px;
}

.video-gallery-right-bottom {
    object-fit: cover;
    object-position: center;
    width: 100%;
    margin-top: 4px;
    flex-grow: 1;
    height: 150px;
}