.field-selector-header-background {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 50px;
    width: 100%;
}

.field-selector-header-input {
    border-width: 0px;
    border-bottom-style: solid;
    border-bottom-width: 0px;
    font-size: 12pt;
    font-weight: bold;
    height: 50px;
    outline: none;
    padding-left: 24px;
    width: 90%;
}

.field-selector-header-close {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    width: 10%;
}