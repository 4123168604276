.progress-mask {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10000;
}

.progress-content {
  text-align: center;
}

.progress-status-message {
  margin-bottom: 20px;
}

.progress-circle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 50px;
  width: 100%;
}
