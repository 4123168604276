.field-selector-container {
    border-right-width: 0px;
    border-right-style: dotted;
    border-top-width: 0px;
    border-top-style: dotted;
    width: 100%;

}

.field-selector-fields {
    flex-grow: 1;
    height: calc(100vh - 160px);
    overflow-y: auto;
    padding: 10px;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
}

.field-selector-fields-mobile {
    flex-grow: 1;
    height: calc(100vh - 110px);
    overflow-y: auto;
    padding: 10px;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
}

.field-selector-field {
    cursor: pointer;
    border-width: 1px;
    border-style: solid;
    border-radius: 9px;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    padding: 12px;
    padding-left: 18px;
    padding-right: 18px;
    width: 100%;
}

.field-selector-close {
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    font-size: 11pt;
    font-weight: bold;
    justify-content: center;
    height: 50px;
    padding-bottom: 3px;
    padding-top: 10px;
}