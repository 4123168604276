.desktop-container {
    align-items: center;
    display: flex;
    height: 100vh;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    transition: background-color 0.3s;
}

.desktop-field-selector {
    width: 40%;
}

.desktop-field-selector-header {
    align-items: center;
    background-color: transparent;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    font-size: 16pt;
    font-weight: bold;
    height: 40px;
    justify-content: left;
    margin-bottom: 10px;
    padding-left: 18px;
    text-wrap: nowrap;
    user-select: none;
}

.desktop-title-bar {
    width: 100%;
}

.desktop-center {
    display: flex;
    flex-direction: row;
    justify-content: left;
    height: calc(100vh - 60px);
    position: relative;
    width: 100%;
}

.desktop-content {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.desktop-summaries {
    height: 100vh;
}

.desktop-menu-mask {
    background-color: rgba(0, 0, 0, 0.5);
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 4;
}

.desktop-menu {
    position: absolute;
    left: -100%;
    top: 0px;
    transition: left 0.3s ease;
    width: 240px;
    z-index: 1000;
}

.desktop-menu.visible {
    left: 0;
}

.desktop-title {
    display: block;
    width: 100%;
}

@media (max-width: 768px) {

    .desktop-center {
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        box-shadow: none;
        width: 100%;
    }

}