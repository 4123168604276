.form-container {
    display: flex;
    flex-direction: column;
    margin: 0px;
    height: calc(100vh - 50px);
    position: relative;
    width: 40%;
}

.form-scroller {
    height: calc(100vh - 180px); /* Default height */
    overflow-y: auto;
    padding-left: 14px;
    padding-right: 14px;
    scrollbar-color: transparent transparent;
    scrollbar-width: thin;
    transition: height 0.3s ease; /* Smooth transition for height */
}

.form-scroller.shrink {
    height: calc(100vh - 170px); /* Shrink by 70px when the button is visible */
}

.form-scroller.expand {
    height: calc(100vh - 100px); /* Default height when the button is hidden */
}


.form-buttons {
    align-items: center;
    bottom: -100px; /* Hidden off-screen */
    height: 80px;
    left: 0px;
    position: absolute;
    right: 0px;
    width: 100%;
    z-index: 2;
    opacity: 0;
    transition: bottom 0.3s ease, opacity 0.3s ease;
}

.form-buttons.visible {
    bottom: 0px; /* Slide up to be visible */
    opacity: 1; /* Fade in */
}

.form-buttons.hidden {
    bottom: -100px; /* Slide down to hide */
    opacity: 0; /* Fade out */
}

.form-container-mobile {
    display: flex;
    flex-direction: column;
    margin: 0px;
    height: calc(100vh - 50px);
    overflow-y: auto;
    position: relative;
    scrollbar-color: transparent transparent;
    scrollbar-width: thin;
    width: 100%;
}

/* Scrollbar Classes*/
.form-container::-webkit-scrollbar,
.form-container-mobile::-webkit-scrollbar {
    width: 8px;
}

.form-container::-webkit-scrollbar-thumb,
.form-container-mobile::-webkit-scrollbar-thumb {
    background-color: #555;
    border-radius: 4px;
}

.form-container::-webkit-scrollbar-thumb:hover,
.form-containermobile::-webkit-scrollbar-thumb:hover {
    background-color: transparent;
}