.app-bar-add-container {
    border-left: 3px solid transparent;
    display: flex;
    flex-direction: row;
    height: 50px;
    justify-content: center;
    margin-bottom: 10px;
    opacity: 0.8;
    position: relative;
    width: 60px;
}

.app-bar-add-circle {
    align-items: center;
    border-radius: 50%;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    justify-content: center;
    height: 40px;
    margin-right: 3px;
    overflow: hidden;
    transition: background-color 0.2s;
    user-select: none;
    width: 40px;
}