.channel-item-container {
    align-items: center;
    background-color: transparent;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    height: 30px;
    flex-direction: row;
    margin-left: 12px;
    margin-right: 12px;
    margin-bottom: 2px;
    padding: 3px;
    padding-left: 8px;
    transition: background-color 0.2s;
}

.channel-item-left {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
}

.channel-item-right {
    align-items: center;
    display: flex;
    flex-direction: row;
}

.channel-item-text {
    font-size: 11pt;
    font-weight: bold;
    margin-top: 4px;
    margin-bottom: 5px;
    margin-left: 8px;
    margin-right: 8px;
    overflow: hidden;
    user-select: none;
    white-space: nowrap;
    width: 160px;
}

@media (max-width: 768px) {

    .channel-item-container {
        height: 42px;
    }

    .channel-item-text {
        font-size: 13pt;
        padding-top: 1px;
    }

}