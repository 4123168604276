.forum-posts-container {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 100px);
    overflow-y: auto;
    padding-bottom: 20px;
    padding-top: 10px;
    width: 100%;
}

.forum-posts-post-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.forum-posts-post {
    border-radius: 13px;
    border-right-style: solid;
    border-right-width: 5px;
    cursor: pointer;
    flex-shrink: 1;
    font-size: 11pt;
    font-weight: normal;
    height: auto;
    line-height: 1.5em;
    margin-left: 20px;
    margin-right: 20px;
    width: 100%;
}

.forum-posts-post-timestamp {
    font-size: 9pt;
    font-weight: normal;
    margin-left: 8px;
}

.forum-posts-post-remove-container {
    cursor: pointer;
    width: 40px;
}

.forum-posts-divider {
    border-bottom-width: 1px;
    border-bottom-style: dotted;
    margin: 20px;
}