.elements-container {
    border-right-width: 1px;
    border-right-style: dotted;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%; 
    max-width: 230px;
    min-width: 230px;
    transition: width 0.1s ease-in;
    user-select: none;
}

.elements-scroller {
    height: 100%; 
    overflow-y: auto;
}

.elements-section-title {
    font-size: 11pt;
    font-weight: bold;
    margin-left: 20px;
    margin-bottom: 10px;
    user-select: none;
}

.elements-section-wrapper {
    margin-bottom: 20px;
    margin-left: 10px;
    margin-top: 14px;
}