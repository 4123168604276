

.user-fields-group-section {
  border: 1px dotted;
  border-radius: 7px;
  margin-bottom: 14px;
  padding: 20px;
  padding-bottom: 16px;
  padding-top: 16px;
}

.user-fields-group-title {
  font-size: 11pt;
  font-weight: bold;
  margin-bottom: 10px;
  margin-left: 2px;
}