.app-selector-outer-container {
    margin-right: 32px;
    position: relative;
    user-select: none;
    width: auto;
}

.app-selector-container {
    align-items: center;
    justify-content: left;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 7px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    font-size: 10pt;
    font-weight: bold;
    height: 36px;
    overflow: hidden;
    padding: 8px;
    padding-left: 12px;
    padding-right: 12px;
    position: relative;
    width: auto;
}

.app-selector-triangle {
    margin-right: 8px;
    margin-top: 1px;
}

.app-selector-dropdown-menu {
    border-width: 1px;
    border-style: solid;
    box-shadow: 4px 8px 10px rgba(0, 0, 0, 0.3);
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    left: 8px;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 34px;
    z-index: 1000;
}

.app-selector-dropdown-menu-item {
    cursor: pointer;
    font-size: 9pt;
    font-weight: normal;
    padding: 10px 10px;
    text-wrap: nowrap;
}
