.menu-item-menu-outer-container {
    position: relative;
}

.menu-item-menu-container {
    align-items: top;
    justify-content: right;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 7px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    font-size: 9pt;
    font-weight: bold;
    justify-content: center;
    margin-right: 14px;
    overflow: hidden;
    padding-bottom: 4px;
    position: relative;
    width: 100%;
}

.menu-item-menu-confirm-message {
    font-size: 10pt;
    line-height: 1.5em;
    margin-bottom: 16px;
    margin-top: 16px;
    text-align: center;
}
