.video-gallery-caption-container {
  height: auto;
  margin-bottom: 12px;
  position: relative;
}

.video-gallery-caption-input {
  align-items: center;
  border-width: 0px;
  border-bottom-left-radius: 11px;
  border-bottom-right-radius: 11px;
  bottom: 0px;
  display: flex;
  font-size: 10pt;
  min-height: 1.0em; /* Set min-height to one line */
  left: 0px;
  outline: none;
  overflow-y: hidden; /* Hide scrollbar */
  padding-left: 8px;
  padding-top: 8px;
  resize: none; /* Prevent manual resizing */
  right: 0px;
  width: 100%;
  z-index: 2;
}
