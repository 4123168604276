.app-settings-container {
    display: flex;
    flex-direction: column;
    height: auto;
    margin: 10px;
    margin-bottom: 10px;
}

.app-settings-content {
    height: 400px;
    width: 600px;
}

.app-settings-divider {
    font-size: 12px;
    font-weight: bold;
    height: 426px;
    margin-left: 32px;
    margin-right: 32px;
    width: 2px;
}

.app-settings-menu {
    height: 400px;
    width: 200px;
}

.app-settings-menu-option {
    border-radius: 7px;
    cursor: pointer;
    margin-bottom: 4px;
    padding: 8px;
}

.app-settings-menu-option-title {
    font-size: 10pt;
    font-weight: bold;
    margin-bottom: 4px;
    margin-left: 4px;
    margin-top: 3px;
}

.app-settings-modal-button {
    background: transparent;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 10pt;
    font-weight: bold;
    margin-top: 20px;
    padding: 10px 20px;
}

.app-settings-modal-button-delete {
    background: transparent;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 10pt;
    font-weight: bold;
    padding: 10px 20px;
}

.app-settings-modal-buttons {
    display: flex;
    justify-content: center;
    margin-top: 36px;
    width: 100%;
}

.app-settings-wrapper {
    display: flex;
    flex-direction: row;
    margin: 10px;
    margin-bottom: 10px;
    margin-top: 10px;
}