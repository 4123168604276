.conversation-container {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 60px);
    width: 100%;
}

.conversation-toolbar {
    margin-bottom: 8px;
}

.conversation-messages {
    display: flex;
    flex-direction: column-reverse;
    height: calc(100vh - 60px);
    overflow-y: auto;
    width: 100%;
}

.conversation-footer {
    display: flex;
    flex-direction: row;
    gap: 8px;
    margin-top: 10px;
    padding: 8px;
    position: relative;
    width: 100%;
}

.conversation-input {
    border-width: 0px;
    border-radius: 24px;
    box-sizing: border-box;
    flex-grow: 1;
    font-size: 11pt;
    margin-left: 12px;
    max-height: 400px;
    outline: none;
    overflow: hidden;
    padding: 16px;
    resize: none;
}

.conversation-send-button {
    align-items: flex-end; /* Aligns the icon at the bottom */
    display: flex;
    padding-bottom: 12px;
    width: 40px;
}

