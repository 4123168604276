.month-picker-container {
  display: flex;
  flex-direction: column;
}

.month-picker-title-container {
  cursor: pointer;
  display: flex;
  flex-direction: row;
}

.month-picker-title {
  font-size: 12pt;
  font-weight: bold;
  margin-left: 0px;
  user-select: none;
}

.month-picker-expand-icon {
  margin-left: 12px;
  margin-top: 2px;
}
