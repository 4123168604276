.gallery-preview-container {
  display: flex;
  flex-direction: column;
  padding-top: 8px;
  position: relative;
}

.gallery-preview-label {
  font-size: 10pt;
  margin-left: 12px;
}

.gallery-preview-wrapper {
  align-items: center;
  border-radius: 11pt;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 270px;
  overflow: hidden;
  width: 100%;
}

.gallery-preview-image {
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  width: 100%;
}

.gallery-preview-image-count {
  border-radius: 5px;
  bottom: 10px;
  font-size: 10pt;
  left: 10px;
  padding: 5px 10px;
  position: absolute;
  z-index: 2;
}

.gallery-preview-nav-button {
  align-items: center;
  border-radius: 7px;
  cursor: pointer;
  display: flex;
  height: 36px;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 36px;
  z-index: 2;
}

.gallery-preview-image{
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  width: 100%;
}