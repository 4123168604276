.settings-container {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 50px);
    overflow-y: auto;
    padding-top: 30px;
    padding-left: 20px;
    padding-right: 20px;
}

.settings-setting-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 30px;
}

.settings-setting-label {
    font-size: 12pt;
    font-weight: bold;
    margin-bottom: 10px;
}

.settings-setting-about-wrapper {
    display: flex;
    flex-direction: column;
}

.settings-setting-about-fields {
    margin-top: 20px;
}

.settings-setting-themes-wrapper {
    display: flex;
    flex-direction: column;
}

.settings-setting-theme-wrapper {
    margin-left: 20px;
    margin-top: 30px;
}

.settings-delete {
    background: transparent;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 10pt;
    font-weight: bold;
    margin-bottom: 100px;
    margin-top: 50px;
    padding: 10px 20px;
}