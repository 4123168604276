.relationships-preview-container {
  background-color: transparent;
  border-radius: 11px;
  display: flex;
  flex-direction: column;
  height: 150px;
  margin-top: 12px;
  padding: 2px;
  padding-bottom: 6px;
  padding-left: 4px;
  padding-top: 12px;
}

.relationships-preview-label {
  font-size: 10pt;
  margin-left: 12px;
}