.app-users-container {
    border-right-width: 1px;
    border-right-style: solid;
    border-top-width: 0px;
    border-top-style: solid;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 440px;
}

.app-users-list {
    overflow-y: auto;
    padding-top: 22px;
    padding-left: 10px;
    padding-right: 10px;
    flex-grow: 1;
    width: 100%;
}

.app-users-user {
    border-width: 2px;
    border-style: solid;
    border-color: transparent;
    border-radius: 9px;
    cursor: pointer;
    margin-bottom: 6px;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px;
    overflow: hidden;
    position: relative;
    user-select: none;
    height: 68px;
}

.app-users-name {
    position: absolute;
    font-size: 12pt;
    font-weight: bold;
    margin-left: 10px;
    margin-top: 10px;
}

.app-users-role {
    position: absolute;
    font-size: 14px;
    font-weight:  normal;
    margin-left: 10px;
    margin-top: 40px;
}

.app-users-divider {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    margin-bottom: 9px;
}

.app-users-add-button {
    position: absolute;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
}

@media (max-width: 768px) {

    .app-users-add-button {
        position: absolute;
        bottom: 20px;
        right: 20px;
        z-index: 1000;
    }

    .app-users-container {
        border-top-width: 0px;
        border-right-width: 0px;
        border-left-width: 0px;
        padding-top: 0px;
        width: 100%;
    }

    .app-users-add-button {
        position: fixed; /* Changed from absolute to fixed */
        bottom: 20px;
        right: 20px;
        z-index: 1000; /* Ensure it's above other content */
    }

    .app-users-list {
        height: calc(100vh - 90px);

        /* Hide scrollbar for WebKit browsers (Chrome, Safari) */
        -webkit-overflow-scrolling: touch; /* Smooth scrolling for touch devices */
    }

    .app-users-list::-webkit-scrollbar {
        display: none; /* Hide the scrollbar for WebKit browsers */
    }
    
    /* Hide scrollbar for Firefox */
    .app-users-list {
        scrollbar-width: none; /* Hide the scrollbar in Firefox */
        -ms-overflow-style: none; /* Hide the scrollbar in Internet Explorer 10+ */
    }

}