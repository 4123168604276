.signup-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

}

.signup-input {
    border-width: 1px;
    border-style: solid;
    border-radius: 7px;
    box-sizing: border-box;
    padding: 12px;
    justify-content: center;
    margin-bottom: 14px;
    width: 80%;
}

.signup-name-wrapper {
    display: flex;
    flex-direction: row;
    gap: 11px;
    width: 80%;
}

.signup-name-input {
    border-width: 1px;
    border-style: solid;
    border-radius: 7px;
    box-sizing: border-box;
    padding: 12px;
    justify-content: center;
    margin-bottom: 14px;
    width: 48%;
}

.signup-birthdate-wrapper {
    display: flex;
    flex-direction: row;
    gap: 11px;
    width: 80%;
}

.signup-birthdate-input {
    border-width: 1px;
    border-style: solid;
    border-radius: 7px;
    box-sizing: border-box;
    padding: 12px;
    justify-content: center;
    margin-bottom: 14px;
    width: 38%;
}

.signup-button {
    align-items: center;
    border-radius: 7px;
    cursor: pointer;
    display: flex;
    font-size: 10pt;
    font-weight: bold;
    justify-content: center;
    margin: 10px 0;
    margin-bottom: 28px;
    margin-top: 24px;
    padding: 13px;
    width: 60%;
}

.signup-container form {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    width: 100%;
}

.signup-signup-link {
    font-size: 12px;
    margin-bottom: 24px;
}

.signup-password-error {
    font-size: 9pt;
    margin-top: 12px;
    margin-bottom: 4px;
    width: 280px;
}

.signup-login-link {
    cursor: pointer;
    font-size: 10pt;
    font-weight: bold;
    margin-top: 16px;
    margin-bottom: 24px;
    user-select: none;
}