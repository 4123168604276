.resize-handle-container {
    border-width: 1px;
    border-style: solid;
    cursor: pointer;
    height: 8px;
    position: absolute;
    width: 8px;
  }
  
  .resize-handle-container.top-left {
    top: -4px;
    left: -4px;
    cursor: nwse-resize;
  }
  
  .resize-handle-container.top-right {
    top: -4px;
    right: -4px;
    cursor: nesw-resize;
  }
  
  .resize-handle-container.bottom-left {
    bottom: -4px;
    left: -4px;
    cursor: nesw-resize;
  }
  
  .resize-handle-container.bottom-right {
    bottom: -4px;
    right: -4px;
    cursor: nwse-resize;
  }