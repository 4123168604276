.app-bar-item-container {
    border-left: 3px solid;
    display: flex;
    flex-direction: row;
    height: 50px;
    justify-content: center;
    margin-bottom: 10px;
    position: relative;
    width: 60px;
}

.app-bar-item-app-container {
    height: 40px;
    position: relative;
    width: 40px;
}

.app-bar-item-dot {
    position: absolute;
    z-index: 0;
}

.app-bar-item-count {
    align-items: center;
    border: 3px solid;
    border-radius: 50%;
    bottom: -6px;
    display: flex;
    flex-direction: row;
    font-family: Arial;
    font-size: 8px;
    height: 22px;
    justify-content: center;
    right: -6px;
    position: absolute;
    width: 22px;
    z-index: 1;
}