.forum-post-item-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.forum-post-item-left {
    display: flex;
    justify-content: space-around;
    width: auto;
}

.forum-post-item-center {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.forum-post-item-right {
    align-items: top;
    display: flex;
    flex-direction: row;

}

.forum-post-item-header {
    display: flex;
    flex-direction: row;
}

.forum-post-item-user {
    align-items: center;
    cursor: pointer;
    display: flex;
    font-size: 11pt;
    font-weight: bold;
    margin-left: 12px;
    width: auto;
}

.forum-post-item-gallery-wrapper {
    border-radius: 17px;
    height: 350px;
    margin-left: 45px;
    margin-right: 12px;
    margin-top: 18px;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    overflow: hidden;
    position: relative;
}

.forum-post-item-dot {
    display: flex;
    justify-content: space-around;
    width: auto;
}

.forum-post-item-user {
    align-items: center;
    cursor: pointer;
    display: flex;
    font-size: 10pt;
    font-weight: bold;
    margin-left: 12px;
    width: auto;
}

.forum-post-item-posted {
    font-weight: normal;
}

.forum-post-item-timestamp {
    align-items: center;
    display: flex;
    flex-direction: row;
    font-size: 9pt;
    font-weight: normal;
    margin-left: 8px;
}

.forum-post-item-title {
    font-size: 13pt;
    font-weight: bold;
    margin-left: 12px;
    padding-bottom: 3px;
    padding-top: 7px;
    white-space: pre-wrap;
    /* Preserve spaces and line breaks */
    word-wrap: break-word;
    /* Prevent long words from overflowing */
}

.forum-post-item-content {
    font-size: 11pt;
    margin-left: 12px;
    padding-bottom: 0px;
    padding-top: 4px;
    white-space: pre-wrap;
    /* Preserve spaces and line breaks */
    word-wrap: break-word;
    /* Prevent long words from overflowing */
}

.forum-post-item-comment-section {
    margin-left: 42px;
}

.forum-post-item-documents-wrapper {
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    padding-bottom: 6px;
    padding-right: 20px;
    padding-top: 12px;
}

.forum-post-item-document {
    align-items: center;
    border-radius: 9px;
    cursor: pointer;
    display: flex;
    flex-shrink: 1;
    gap: 12px;
    margin-right: auto;
    margin-top: 5px;
    padding: 12px;
    padding-left: 16px;
    padding-right: 20px;
    width: auto;
}

.forum-post-item-document-filename {
    font-size: 10pt;
    font-weight: bold;
}

.forum-post-item-object-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: left;
}

.forum-post-item-object {
    border-radius: 13px;
    margin: 20px;
    margin-left: 45px;
    padding: 8px;
    width: 440px;
}

.forum-post-item-right {
    width: 70px;
}

@media (max-width: 600px) {

    .forum-post-item-gallery-wrapper {
        height: 200px;
    }

}