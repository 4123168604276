.build-apps-title-container {
    flex-grow: 1;
    font-size: 16pt;
    font-weight: bold;
    margin-bottom: 30px;
    margin-left: 40px;
    margin-top: 12px;
    user-select: none;
}

.build-apps-background {
    border-radius: 7px;
    box-sizing: border-box;
    display: grid;
    gap: 20px;
    justify-content: space-between;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 40px;
    max-height: 600px;
}

/* Styles for small screens */
@media screen and (max-width: 768px) {

    .build-apps-background {
        /*display: flex;
        flex-direction: column;*/
        grid-template-columns: repeat(3, 1fr) !important;
    }

}