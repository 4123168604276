.logo-small-container {
    display: flex;
    flex-direction: row;
}

.logo-small-left {
    align-items: center;
    display: flex;
    flex-direction: row;
    font-family: 'Red Hat Display', 'Open Sans', 'Roboto', 'Arial';
    font-size: 11pt;
    font-weight: normal;
    letter-spacing: .06em;
}

.logo-small-right {
    align-items: center;
    display: flex;
    flex-direction: row;
    font-family: 'Red Hat Display', 'Open Sans', 'Roboto', 'Arial';
    font-size: 11pt;
    font-weight: normal;
    letter-spacing: .06em;
}

.logo-small-symbol-icon {
    width: 16px;
}

.logo-small-symbol-wrapper {
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-right: 5px;
    margin-top: 4px;
}

.logo-small-text-wrapper {
    align-items: center;
    display: flex;
    flex-direction: row;
}

.logo-small-trademark {
    font-size: 8pt;
    font-weight: normal;
    height: 28px;
    margin-left: 0px;
}