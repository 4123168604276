.logo-large-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.logo-large-app-logo {
    margin-bottom: 40px;
    margin-right: 70px;
}

.logo-large-company {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-family: 'Red Hat Display', 'Open Sans', 'Roboto', 'Arial';
    font-size: 36pt;
    font-weight: normal;
    letter-spacing: .06em;
}

.logo-large-tagline {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-family: 'Red Hat Display', 'Open Sans', 'Roboto', 'Arial';
    font-size: 10pt;
    font-weight: normal;
    letter-spacing: .22em;
    margin-top: 6px;
}

.logo-large-est-wrapper {
    align-items: top;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 10px;
    width: 100%;
}

.logo-large-est {
    width: 100px;
}

.logo-large-left {
    font-family: 'Red Hat Display', 'Open Sans', 'Roboto', 'Arial';
    font-size: 50pt;
    font-weight: normal;
    user-select: none;
}

.logo-large-right {
    font-family: 'Red Hat Display', 'Open Sans', 'Roboto', 'Arial';
    font-size: 50pt;
    font-weight: normal;
    margin-left: 0px;
    user-select: none;
}

.logo-large-image {
    width: 300px;
}

.logo-large-symbol-icon {
    user-select: none;
    width: 40px;
}

.logo-large-symbol-large {
    margin-right: 10px;
    margin-top: 24px;
    display: block;
}

.logo-large-symbol-small {
    margin-right: 10px;
    margin-top: 32px;
    display: none;
}

.logo-large-trademark {
    font-size: 10pt;
    font-weight: normal;
    margin-left: 4px;
    margin-top: 4px;
    user-select: none;
}

/* Styles for small screens */
@media screen and (max-width: 768px) {

    .logo-large-wrapper {
        margin-bottom: 10px;
    }

    .logo-large-company {
        font-size: 27pt;
    }

    .logo-large-tagline {
        font-size: 8pt;
        letter-spacing: .18em;
    }

    .logo-large-left {
        font-size: 28pt;
    }

    .logo-large-right {
        font-size: 28pt;
    }

    .logo-large-image {
        width: 76px;
    }

    .logo-large-symbol-small {
        margin-right: 10px;
        margin-top: 17px;
        display: block;
    }

    .logo-large-symbol-large {
        margin-right: 10px;
        margin-top: 32px;
        display: none;
    }
}