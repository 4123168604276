.login-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 55px;
    user-select: none;
}

.login-input {
    border-width: 1px;
    border-style: solid;
    border-radius: 7px;
    box-sizing: border-box;
    padding: 12px;
    justify-content: center;
    margin-bottom: 14px;
    width: 80%;
}

.login-error {
    border-width: 2px;
    border-style: solid;
    border-radius: 7px;
    box-sizing: border-box;
    display: flex;
    font-size: 9pt;
    justify-content: center;
    margin-top: 2px;
    padding: 12px;
    width: 80%;
}

.login-button {
    border-width: 1px;
    border-style: solid;
    border-radius: 7px;
    cursor: pointer;
    font-weight: bold;
    margin: 10px 0;
    margin-bottom: 28px;
    margin-top: 24px;
    padding: 12px;
    width: 60%;
}

.login-container form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 16px;
}

.login-signup-link {
    cursor: pointer;
    font-size: 10pt;
    font-weight: bold;
    margin-top: 16px;
    margin-bottom: 24px;
    user-select: none;
}

/* Styles for small screens */
@media screen and (max-width: 768px) {
    
    .login-container {
        margin-top: 0px;
    }

}