.user-thumb-container {
    background-color: green;
    border-radius: 17px;
    display: flex; 
    flex-direction: column;
    height: 100%;
    width: 100%;
    z-index: 500;
}

.user-thumb-photo {
    height: 100%;
    object-fit: cover;
    object-position: center;
    width: 100%;
}