.menu-header-container {
    align-items: center;
    align-content: center;
    background-color: transparent;
    justify-content: center;
    display: flex;
    height: 150px;
    flex-direction: column;
    padding: 12px;
    padding-bottom: 20px;
    width: 100%;
}

.menu-header-logo {
    height: 80%;
    margin-left: 6px;
    margin-top: 7px;
    margin-right: 6px;
}

.menu-header-title {
    cursor: pointer;
    font-size: 12pt;
    font-weight: normal;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 12px;
    overflow: hidden;
    text-wrap: nowrap;
    user-select: none;
}