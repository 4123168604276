
.gallery-headline-wrapper {
    border-radius: 17px;
    height: 250px;
    margin-top: 12px;
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    overflow: hidden;
    position: relative;
}