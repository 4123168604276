.location-container {
  background-color: transparent;
  border-radius: 11px;
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  padding: 2px;
  padding-bottom: 6px;
  padding-left: 4px;
  padding-top: 12px;
}

.location-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.location-error-message {
  font-size: 10px;
}

.location-input {
  background-color: transparent;
  border: 0px;
  border-radius: 5px;
  font-size: 12pt;
  font-weight: bold;
  line-height: 1.5em;
  margin-top: 6px;
  outline: none;
  padding: 12px;
  padding-bottom: 8px;
  padding-left: 0px;
  padding-top: 8px;
}

.location-label {
  font-size: 10pt;
  margin-left: 12px;
  user-select: none;
}

.location-address {
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 7px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  outline: none;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 2px;
}

.location-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 8px;
}

.location-directions-buttons {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 8px;
}

.location-directions-button {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 11pt;
  font-weight: bold;
  gap: 8px;
  height: 30px;
  justify-content: left;
  margin-bottom: 2px;
  margin-left: 15px;
  margin-top: 10px;
  width: auto;
}