.menu-container {
    border-right-width: 1px;
    border-right-style: solid;
    height: 100%;
    padding-top: 8px;
    min-width: 250px;
}

@media (max-width: 768px) {

    .menu-container {
        border-right-width: 0px;
        display: flex;
        flex-direction: row;
        gap: 10px;
        min-width: 350px;
        padding: 10px;
        width: 350px;
    }

    .menu-background {
        width: 350px;
    }
}