.account-billing-container {
    align-content: top;
    box-sizing: border-box;
    display: flex;
    height: 100%;
    flex-direction: column;
    overflow-y: auto;
    width: 440px;
}

.account-billing-selectors {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}

.account-billing-totals {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 0px;
}

.account-billing-totals-top {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 120px;
    justify-content: center;
    width: 100%;
}

.account-billing-totals-bottom {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    height: 120px;
    justify-content: center;
    width: 100%;
}

.account-billing-total-wrapper {
    display: flex;
    flex-direction: column;
    margin: 20px;
    margin-top: 50px;
    margin-bottom: 50px;
}

.account-billing-totals-table {
    border-top-width: 1px;
    border-top-style: dotted;
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 30px;
    margin-bottom: 300px;
}

.account-billing-total-label {
    border-left-width: 1px;
    border-left-style: dotted;
    border-bottom-width: 1px;
    border-bottom-style: dotted;
    font-size: 16px;
    font-weight: bold;
    justify-content: left;
    padding: 10px;
    padding-bottom: 14px;
    padding-left: 14px;
    width: 50%;
}

.account-billing-total-value {
    border-left-width: 1px;
    border-left-style: dotted;
    border-bottom-width: 1px;
    border-bottom-style: dotted;
    border-right-width: 1px;
    border-right-style: dotted;
    font-size: 12pt;
    font-weight: bold;
    justify-content: right;
    padding: 10px;
    padding-right: 14px;
    padding-bottom: 14px;
    text-align: right;
    width: 50%;
}

.account-billing-overall-label {
    font-size: 12pt;
    font-weight: bold;
    justify-content: left;
    padding: 10px;
    padding-bottom: 14px;
    padding-left: 14px;
    width: 50%;
}

.account-billing-overall-app {
    font-size: 9pt;
    font-weight: normal;
    justify-content: left;
    margin-top: 10px;
}

.account-billing-overall-value {
    font-size: 24px;
    font-weight: bold;
    justify-content: right;
    padding: 10px;
    padding-right: 14px;
    padding-bottom: 14px;
    text-align: right;
    width: 50%;
}

.account-billing-chart {
    align-items: left;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 40px;
    padding-right: 40px;
    width: 100%;
}

.account-billing-chart-tooltip {
    align-items: center;
    border-radius: 11px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    opacity: .5;
    width: 100px;
}

.account-billing-chart-tooltip-label {
    font-size: 12pt;
    font-weight: bold;
}

@media (max-width: 768px) {

    .account-billing-container {
        width: 100%;
    }

}