.post-headline-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.post-headline-left {
    display: flex;
    justify-content: space-around;
    width: auto;
}

.post-headline-right {
    display: flex;
    flex-direction: column;

}

.post-headline-header {
    display: flex;
    flex-direction: row;
}

.post-headline-user {
    align-items: center;
    cursor: pointer;
    display: flex;
    font-size: 11pt;
    font-weight: bold;
    margin-left: 12px;
    width: auto;
}

.post-headline-gallery-wrapper {
    border-radius: 17px;
    height: 350px;
    margin-right: 12px;
    margin-top: 18px;
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    overflow: hidden;
    position: relative;
}

.post-headline-dot {
    display: flex;
    justify-content: space-around;
    width: auto;
}

.post-headline-user {
    align-items: center;
    cursor: pointer;
    display: flex;
    font-size: 11pt;
    font-weight: bold;
    margin-left: 12px;
    width: auto;
}

.post-headline-posted {
    font-weight: normal;
}

.post-headline-timestamp {
    align-items: center;
    display: flex;
    flex-direction: row;
    font-size: 9pt;
    font-weight: normal;
    margin-left: 8px;
}

.post-headline-content {
    font-size: 11pt;
    margin-left: 12px;
    padding-bottom: 0px;
    padding-top: 4px;
    white-space: pre-wrap;
    /* Preserve spaces and line breaks */
    word-wrap: break-word;
    /* Prevent long words from overflowing */
}

.post-headline-comment-section {
    margin-left: 42px;
}

.post-headline-documents-wrapper {
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    padding-bottom: 6px;
    padding-right: 20px;
    padding-top: 12px;
}

.post-headline-document {
    align-items: center;
    border-radius: 9px;
    cursor: pointer;
    display: flex;
    flex-shrink: 1;
    gap: 12px;
    margin-right: auto;
    margin-top: 5px;
    padding: 12px;
    padding-left: 16px;
    padding-right: 20px;
    width: auto;
}

.post-headline-document-filename {
    font-size: 10pt;
    font-weight: bold;
}

@media (max-width: 600px) {

    .post-headline-gallery-wrapper {
        height: 200px;
    }

}