.run-home-post-container {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-right-width: 1px;
    border-right-style: solid;
    display: flex;
    flex-direction: column;
    max-width: 600px;
    /* Set a maximum width for larger screens */
}

.run-home-post-wrapper {
    display: flex;
    flex-direction: row;
    height: auto;
    width: 100%;
    /* Change to 100% to make it responsive */
    max-width: 600px;
    /* Set a maximum width for larger screens */
    box-sizing: border-box;
    /* Include padding and border in the element's total width and height */
}

.run-home-post-left {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding-left: 16px;
    padding-top: 15px;
    padding-right: 10px;
    width: auto;
}

.run-home-post-center {
    flex-grow: 1;
    flex-shrink: 1;
    /* Allow it to shrink if needed */
}

.run-home-post-right {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding-left: 16px;
    padding-top: 4px;
    padding-right: 8px;
    width: auto;
    flex-shrink: 0;
    /* Prevent it from shrinking */
}

.run-home-post-button {
    align-items: center;
    border-radius: 11px;
    cursor: pointer;
    display: flex;
    font-size: 11pt;
    font-weight: bold;
    height: 36px;
    margin-right: 14px;
    margin-top: 13px;
    padding-left: 12px;
    padding-right: 12px;
    text-align: center;
    width: auto;
}

.run-home-post-input {
    font-size: 20px;
    font-weight: normal;
    border-width: 1px;
    border-style: solid;
    margin-bottom: 4px;
    margin-top: 9px;
    outline: none;
    padding-top: 12px;
    padding-bottom: 12px;
    width: 100%;
    resize: none;
    /* Disable manual resizing */
    overflow: hidden;
    /* Hide scrollbar */
}

/* Auto-resize textarea */
.run-home-post-input:focus {
    overflow-y: auto;
    /* Show scrollbar on focus if needed */
}

.run-home-post-attachments {
    height: 0;
    overflow: hidden;
    padding-left: 70px;
    padding-top: 5px;
    transition: height 0.3s ease;
}
.run-home-post-attachment-buttons {
    display: flex;
    flex-direction: row;
}

.run-home-post-attachments.visible {
    height: 60px;
}

.run-home-post-attachments.hidden {
    height: 0;
}

.run-home-post-attachment-button {
    align-items: center;
    border-radius: 7px;
    cursor: pointer;
    display: flex;
    height: 34px;
    justify-content: center;
    margin-right: 12px;
    text-align: center;
    width: 34px;
}

.run-home-post-thumbnails {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    padding-left: 70px;
    padding-bottom: 16px;
}

.run-home-post-thumbnail {
    border-radius: 5px;
    cursor: pointer;
    height: 50px;
    object-fit: cover;
    width: 50px;
}

.run-home-post-documents {
    display: flex;
    flex-direction: column;
    padding-left: 70px;
    padding-bottom: 20px;
    padding-right: 20px;
}

.run-home-post-document {
    align-items: center;
    border-radius: 9px;
    cursor: pointer;
    display: flex;
    flex-shrink: 1;
    gap: 12px;
    margin-right: auto;
    margin-top: 5px;
    padding: 12px;
    padding-left: 16px;
    padding-right: 20px;
    width: auto;
}

.run-home-post-document-filename {
    font-size: 10pt;
    font-weight: bold;
}

@media (max-width: 600px) {
    .run-home-post-input {
        font-size: 13pt;
        overflow: hidden;
        /* Hide scrollbars */
        padding-top: 13px;
        -webkit-overflow-scrolling: touch;
        /* Enable smooth scrolling on iOS */
    }

    .run-home-post-input:focus {
        overflow-y: auto;
        /* Show scrollbar on focus if needed */
        /* Ensure scrollbar remains hidden but allows scrolling */
        scrollbar-width: none;
        /* Firefox */
        -ms-overflow-style: none;
        /* Internet Explorer and Edge */
    }

    .run-home-post-input::-webkit-scrollbar {
        display: none;
        /* Chrome, Safari, and Opera */
    }

    .run-home-post-button {
        font-size: 10pt;
        height: 32px;
    }
}