.modal-overlay {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10000;
}

.modal-content {
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  max-height: 100vh;
  min-width: 250px;
  overflow: hidden;
  padding: 10px;
  user-select: none;
}

.modal-header {
  align-items: center;
  display: flex;
  height: 20px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
  justify-content: space-between;
  user-select: none;
}

.modal-title {
  font-size: 12pt;
  font-weight: bold;
  margin-left: 4px;
  user-select: none;
}

.modal-close-button {
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 1.5em;
  margin-right: 4px;
}

/* Ensures that clicking on the modal content does not close the modal */
.modal-content {
  z-index: 1001;
}

.modal-body {
  padding-bottom: 12px;
  padding-top: 12px;
}

.modal-label {
  font-size: 10pt;
  margin-left: 2px;
  margin-top: 12px;
}

.modal-label-description {
  font-size: 8pt;
  margin-bottom: 10px;
  margin-top: 14px;
}

.modal-label .modal-input,
.modal-label .modal-textarea {
  margin-top: 14px;
}

.modal-label,
.modal-input,
.modal-select,
.modal-textarea {
  margin-left: 5%;
  margin-right: 5%;
  width: 90%;
}

.modal-input,
.modal-select,
.modal-textarea {
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  margin-bottom: 18px;
  margin-top: 20px;
  outline: none;
  padding: 12px;
}

.modal-textarea {
  border-style: solid;
  border-width: 1px;
  height: 100px;
  resize: none;
}

.modal-buttons {
  display: flex;
  justify-content: center;
  margin-top: 8px;
  width: 100%;
}

.modal-button {
  background: transparent;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 10pt;
  font-weight: bold;
  padding: 10px 20px;
}

.modal-button-delete {
  background: transparent;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 10pt;
  font-weight: normal;
  margin-top: 30px;
  padding: 10px 20px;
}

@media (max-width: 768px) {
  .modal-content {
    background-color: transparent;
  }

  .modal-input,
  .modal-select,
  .modal-textarea {
    border-style: solid;
    border-width: 1px;
  }

}