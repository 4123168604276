.app-dot-container {
    align-items: center;
    border-radius: 50%;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    justify-content: center;
    height: 40px;
    margin-right: 3px;
    overflow: hidden;
    transition: background-color 0.2s;
    user-select: none;
    width: 40px;
}

.app-dot-image {
    height: auto;
    min-height: 50%;
    object-fit: cover;
    overflow: visible;
    width: 50%;
}

.app-dot-letter {
    align-items: center;
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    font-size: var(--dot-font-size);
    font-weight: bold;
    justify-content: center;
}