.event-field-checklist-items {
  margin-bottom: 20px;
  margin-top: 20px;
}

.event-field-checklist-item-wrapper {
  display: flex;
  flex-direction: row;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 12px;
  width: 90%;
}

.event-field-checklist-item-checkbox {
  cursor: pointer;
  width: 40px;
}

.event-field-checklist-item-label {
  cursor: pointer;
  font-size: 11pt;
  font-weight: bold;
  padding-top: 3px;
}