.element-group-title {
    font-size: 11pt;
    font-weight: bold;
    margin-left: 20px;
    margin-bottom: 10px;
    user-select: none;
}

.element-group-items {
    margin-bottom: 20px;
    margin-left: 10px;
    margin-top: 14px;
}