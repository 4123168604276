.element-container {
    background-color: transparent;
    border-radius: 5px;
    cursor: pointer;
    display: flex;  
    flex-direction: row;
    margin-left: 12px;
    margin-right: 12px;
    margin-bottom: 2px;
    padding: 3px;
    padding-left: 8px;
}

.element-icon {
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-right: 6px;
    padding-top: 1px;
    vertical-align: center;
}

.element-title {
    font-size: 11pt;
    font-weight: bold;
    margin-bottom: 4px;
    margin-top: 4px;
    margin-left: 4px;
    margin-right: 8px;
    user-select: none;
    white-space: nowrap;
}