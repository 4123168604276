.theme-toggle-container {
    display: flex;
    flex-direction: row;
    height: 20px;
    margin-left: 16px;
    margin-right: 8px;
    width: 40px;
}

.theme-toggle-left {
    background-color: white;
    border-width: 1px;
    border-style: solid;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    cursor: pointer;
    flex-grow: 1;
    height: 100%;
    width: 50%;
}

.theme-toggle-right {
    background-color: black;
    border-width: 1px;
    border-style: solid;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    cursor: pointer;
    flex-grow: 1;
    height: 100%;
    width: 50%;
}