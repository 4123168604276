.chat-post-form-input {
    border-radius: 9px;
    font-size: 18px;
    font-weight: normal;
    border-width: 1px;
    border-style: solid;
    margin-bottom: 4px;
    margin-top: 9px;
    outline: none;
    padding: 12px;
    width: 100%;
    resize: none;
    /* Disable manual resizing */
    overflow: hidden;
    /* Hide scrollbar */
}

/* Auto-resize textarea */
.chat-post-form-input:focus {
    overflow-y: auto;
    /* Show scrollbar on focus if needed */
}

@media (max-width: 600px) {

    .chat-post-form-input {
        font-size: 13pt;
        overflow: hidden;
        /* Hide scrollbars */
        padding-top: 13px;
        -webkit-overflow-scrolling: touch;
        /* Enable smooth scrolling on iOS */
    }

    .chat-post-form-input:focus {
        overflow-y: auto;
        /* Show scrollbar on focus if needed */
        /* Ensure scrollbar remains hidden but allows scrolling */
        scrollbar-width: none;
        /* Firefox */
        -ms-overflow-style: none;
        /* Internet Explorer and Edge */
    }

    .chat-post-form-input::-webkit-scrollbar {
        display: none;
        /* Chrome, Safari, and Opera */
    }

}