.menu-settings-container {
    display: flex;
    flex-direction: column;
    margin: 10px;
    margin-bottom: 10px;
    margin-top: 0px;
}

.menu-settings-wrapper {
    display: flex;
    flex-direction: row;
    margin: 10px;
    margin-bottom: 10px;
    margin-top: 10px;
}

.menu-settings-label {
    font-size: 10pt;
    margin-top: 12px;
}

.menu-settings-left {
    width: 250px;
}

.menu-settings-right {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.menu-settings-bottom {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.menu-settings-label-description {
    font-size: 8pt;
    line-height: 1.5em;
    margin-top: 14px;
    margin-bottom: 10px;
}

.menu-settings-label-description li {
    margin-left: 3px;
}

.menu-settings-divider {
    font-size: 12px;
    font-weight: bold;
    margin-left: 32px;
    margin-right: 32px;
    width: 2px;
    height: 300px;
}

.menu-settings-publish-container {
    align-items: top;
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 20px;
}

.menu-settings-publish-checkbox {
    margin-bottom: 18px;
}

.menu-settings-publish-checkbox-label {
    cursor: pointer;
    font-size: 9pt;
    margin-left: 8px;
    margin-top: 3px;
}

.menu-settings-permissions-header {
    border-bottom: 1px solid #242424;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    margin-top: 12px;
    margin-bottom: 8px;
}

.menu-settings-permissions-header-item {
    box-sizing: border-box;
    font-size: 10pt;
    padding-top: 6px;
    padding-bottom: 6px;
    text-align: center;
    width: 70px;
}

.menu-settings-permissions-role {
    box-sizing: border-box;
    font-size: 10pt;
    font-weight: bold;
    padding-left: 2px;
    padding-top: 6px;
    padding-bottom: 6px;
}

.menu-settings-permissions-role-checkboxes {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    margin-bottom: 4px;
}

.menu-settings-permission-checkbox {
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 70px;
}

.menu-settings-permission-checkbox-image {
    height: 22px;
    width: 22px;
}