.user-search-container {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    z-index: 20000;
}

.user-search-input {
    border: 0px solid;
    border-radius: 9px;
    font-size: 11pt;
    font-weight: bold;
    outline: none;
    padding: 12px;
    width: 100%;
}

.user-search-divider {
    height: 1px;
    margin-bottom: 20px;
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 10px;
}

.user-search-group {
    font-size: 13pt;
    font-weight: bold;
    padding: 8pt;
    padding-left: 20px;
    padding-right: 20px;
}

.user-search-result {
    border-radius: 9px;
    cursor: pointer;
    font-size: 11pt;
    font-weight: bold;
    padding: 8pt;
    padding-left: 30pt;
}