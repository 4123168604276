/* BUTTON WRAPPER */
.calendar-buttons {
  align-content: center;
  border-radius: 7px;
  display: flex;
  flex-direction: row;
  margin-right: 12px;
}

/* BUTTON */
.calendar-button {
  align-content: center;
  background-color: transparent;
  cursor: pointer;
  height: 30px;
  text-align: center;
  width: 30px;
  user-select: none;
}

/* CALENDAR MENU */
.calendar-menu-background {
  background-color: transparent;
  border-width: 1px;
  border-style: solid;
  position: absolute;
  border-radius: 9px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-top: 10px;
  padding-top: 8px;
  padding-bottom: 8px;
  right: 15px;
  z-index: 1000;
}

/* TITLE MENU ITEMS */
.calendar-menu-item {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 10pt;
  padding: 8px;
  padding-left: 18px;
  padding-right: 22px;
  text-align: left;
  user-select: none;
  width: 100%;
}